import { createContext } from "preact";

interface ToastContextProps {
  triggerToast: (message: string, type?: string) => void;
}

const ToastContext = createContext<ToastContextProps>({
  triggerToast: () => undefined,
});

export default ToastContext;
