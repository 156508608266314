import { h } from "preact";

export const RenderLogo = (
  icon: string,
  color?: string,
  width?: number,
  height?: number
) => {
  switch (icon) {
    case "Complete":
      return (
        <svg
          width={width}
          height={height}
          viewBox="0 0 200 56"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.14502 45.5H15.6097C23.841 45.5 30.5105 38.866 30.5105 30.6847V29.6746C30.5443 29.1458 31.0002 28.7401 31.5349 28.7401H47.491C52.5565 28.7653 56.682 24.6998 56.7073 19.6634C56.7327 14.627 52.6437 10.5252 47.5783 10.5H30.6118L30.5274 27.7048C30.5274 28.2672 30.0659 28.7233 29.4974 28.7233H16.0459C7.81734 28.7233 1.14502 35.3573 1.14502 43.5386V45.5Z"
            fill={color}
          />
          <path
            d="M96.1756 14.7809H92.6608V37.2012H96.1756V14.7809Z"
            fill={color}
          />
          <path
            d="M102.966 21.3562H99.4513V37.2012H102.966V21.3562Z"
            fill={color}
          />
          <path
            d="M113.072 20.9001H113.035C110.955 20.9001 108.986 21.7815 107.609 23.3204C106.081 24.9488 105.324 27.0753 105.327 29.2913C105.327 31.5353 106.081 33.6365 107.607 35.2594C108.983 36.8011 110.941 37.6573 113.021 37.6824C113.035 37.6824 113.046 37.6824 113.06 37.6824C115.07 37.6908 116.995 36.9298 118.241 35.3853L118.953 34.4871V37.2012H122.468V21.3562H118.911V24.0814L118.196 23.1805C116.955 21.6612 115.022 20.8945 113.069 20.9001H113.072ZM118.914 28.9191C118.917 29.0283 118.917 29.1346 118.914 29.2437C118.976 30.6035 118.452 31.955 117.476 32.937C116.55 33.866 115.272 34.392 113.958 34.392H113.755C112.453 34.392 111.183 33.866 110.263 32.937C109.318 31.9606 108.803 30.6259 108.845 29.2717C108.797 27.9315 109.315 26.594 110.263 25.6203C111.209 24.6662 112.531 24.1486 113.868 24.171C113.916 24.171 113.964 24.171 114.012 24.171C116.648 24.171 118.838 26.2722 118.914 28.9191Z"
            fill={color}
          />
          <path
            d="M135.087 20.8833H135.047C133.094 20.8833 131.178 21.6332 129.954 23.1637L129.233 24.0702V15.7742H125.676V37.2012H129.191V34.4899L129.911 35.3937C131.147 36.9354 133.044 37.6796 135.047 37.674H135.078C137.166 37.674 139.15 36.7927 140.535 35.2538C142.063 33.6281 142.828 31.5101 142.826 29.2941C142.826 27.0389 142.063 24.946 140.538 23.3176C139.15 21.7703 137.166 20.8861 135.087 20.8861V20.8833ZM137.853 32.9175L137.847 32.9231C136.831 33.8744 135.526 34.3472 134.223 34.3472C132.92 34.3472 131.637 33.8772 130.632 32.9398C129.681 31.9577 129.163 30.6231 129.208 29.2689C129.16 27.9259 129.678 26.5912 130.626 25.6175C131.572 24.6634 132.897 24.1458 134.231 24.1682C135.571 24.1486 136.891 24.655 137.847 25.5979C138.796 26.566 139.319 27.8895 139.285 29.2381C139.328 30.6147 138.807 31.9578 137.853 32.9175Z"
            fill={color}
          />
          <path
            d="M148.701 21.3562H145.187V37.2012H148.701V21.3562Z"
            fill={color}
          />
          <path
            d="M155.498 14.7809H151.983V37.2012H155.498V14.7809Z"
            fill={color}
          />
          <path
            d="M162.294 21.3562H158.779V37.2012H162.294V21.3562Z"
            fill={color}
          />
          <path
            d="M82.3385 20.777H82.2709C80.2279 20.7686 78.2242 21.5436 76.9072 23.0937L76.5723 23.4882V21.1911H73.1222V43.7149H76.5723V35.0076L76.9072 35.3965C78.2439 36.9522 80.2082 37.688 82.2484 37.6964H82.3357C84.4632 37.6964 86.5006 36.8179 87.9443 35.2734C89.5061 33.6673 90.3109 31.4401 90.3081 29.2409C90.3081 26.9885 89.5145 24.8537 87.9471 23.2336C86.5062 21.6696 84.4688 20.777 82.3413 20.777H82.3385ZM85.3946 32.9259L85.389 32.9314C84.3618 33.9107 83.042 34.3976 81.725 34.3976C80.408 34.3976 79.0882 33.9079 78.061 32.9314C77.0789 31.9577 76.5357 30.6091 76.5723 29.2325C76.5301 27.8671 77.062 26.5157 78.0329 25.5364C80.0928 23.5778 83.3122 23.5778 85.3665 25.5336C86.343 26.5157 86.8749 27.8671 86.8327 29.2437C86.8749 30.5979 86.3515 31.9438 85.3975 32.9259H85.3946Z"
            fill={color}
          />
          <path
            d="M178.953 21.3562H170.919V17.0109H170.362L164.247 24.0031V24.5207H167.401V31.9997C167.401 37.1984 170.421 37.3215 172.866 37.3215H174.715V34.1094H172.999C170.922 34.1094 170.902 33.3512 170.902 31.9773V24.5207H176.438L180.425 34.0143H183L180.254 41.58H184.01L191.364 21.3282H187.615L183.462 32.8307L178.953 21.359V21.3562Z"
            fill={color}
          />
          <path
            d="M101.21 18.9471C102.391 18.9471 103.349 17.9951 103.349 16.8207C103.349 15.6462 102.391 14.6942 101.21 14.6942C100.029 14.6942 99.0714 15.6462 99.0714 16.8207C99.0714 17.9951 100.029 18.9471 101.21 18.9471Z"
            fill={color}
          />
          <path
            d="M146.943 18.9471C148.124 18.9471 149.081 17.9951 149.081 16.8207C149.081 15.6462 148.124 14.6942 146.943 14.6942C145.761 14.6942 144.804 15.6462 144.804 16.8207C144.804 17.9951 145.761 18.9471 146.943 18.9471Z"
            fill={color}
          />
          <path
            d="M160.538 18.9471C161.719 18.9471 162.676 17.9951 162.676 16.8207C162.676 15.6462 161.719 14.6942 160.538 14.6942C159.356 14.6942 158.399 15.6462 158.399 16.8207C158.399 17.9951 159.356 18.9471 160.538 18.9471Z"
            fill={color}
          />
          <path
            d="M196.241 26.4513C194.8 26.4513 193.626 25.2846 193.626 23.852C193.626 22.4194 194.8 21.2527 196.241 21.2527C197.682 21.2527 198.855 22.4194 198.855 23.852C198.855 25.2846 197.682 26.4513 196.241 26.4513ZM196.241 21.7283C195.064 21.7283 194.105 22.6796 194.105 23.852C194.105 25.0243 195.062 25.9757 196.241 25.9757C197.42 25.9757 198.377 25.0243 198.377 23.852C198.377 22.6796 197.42 21.7283 196.241 21.7283Z"
            fill={color}
          />
          <path
            d="M197.245 23.3959C197.245 23.1637 197.158 22.9706 196.986 22.8167C196.812 22.66 196.578 22.5817 196.283 22.5817H195.43V25.0915H195.956V24.2437H196.325L196.767 25.0915H197.341L196.815 24.1346C197.102 23.9947 197.245 23.7485 197.245 23.3959ZM196.604 23.6729C196.525 23.7373 196.418 23.768 196.283 23.768H195.956V23.0686H196.283C196.573 23.0686 196.719 23.1861 196.719 23.4211C196.719 23.5246 196.68 23.6086 196.604 23.6729Z"
            fill={color}
          />
        </svg>
      );
    case "Logo-Dark":
      return (
        <svg
          width="167"
          height="167"
          viewBox="0 0 167 167"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 16.7357C0 7.49281 7.49283 0 16.7357 0H150.264C159.507 0 167 7.49283 167 16.7357V150.264C167 159.507 159.507 167 150.264 167H16.7357C7.49281 167 0 159.507 0 150.264V16.7357Z"
            fill="white"
          />
          <path
            d="M29.7426 117.617H57.7321C73.66 117.617 86.5657 104.684 86.5657 88.7339V86.7647C86.6311 85.7337 87.5132 84.9428 88.5479 84.9428H119.424C129.225 84.9919 137.208 77.0661 137.257 67.2474C137.306 57.4288 129.394 49.4321 119.592 49.383H86.7618L86.5984 82.9245C86.5984 84.0209 85.7053 84.9101 84.6054 84.9101H58.5762C42.6537 84.9101 29.7426 97.8434 29.7426 113.793V117.617Z"
            fill="black"
          />
        </svg>
      );

    case "Logo-Select-Journey":
      return (
        <svg
          width="112"
          height="20"
          viewBox="0 0 112 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.641113 20H8.74132C13.3509 20 17.0858 16.2091 17.0858 11.5341V10.9569C17.1047 10.6547 17.36 10.4229 17.6594 10.4229H26.5949C29.4315 10.4373 31.7418 8.11416 31.756 5.23623C31.7702 2.3583 29.4804 0.0143896 26.6437 0H17.1425L17.0953 9.83132C17.0953 10.1527 16.8368 10.4133 16.5185 10.4133H8.98559C4.37761 10.4133 0.641113 14.2042 0.641113 18.8792V20Z"
            fill="white"
          />
          <path
            d="M53.8575 2.44629H51.8892V15.2579H53.8575V2.44629Z"
            fill="white"
          />
          <path
            d="M57.6607 6.20361H55.6924V15.2579H57.6607V6.20361Z"
            fill="white"
          />
          <path
            d="M63.3198 5.94289H63.2994C62.1347 5.94289 61.0316 6.44653 60.261 7.32589C59.4053 8.25642 58.9813 9.47155 58.9829 10.7378C58.9829 12.0201 59.4053 13.2208 60.2594 14.1482C61.03 15.0291 62.1269 15.5184 63.2915 15.5328C63.2993 15.5328 63.3057 15.5328 63.3135 15.5328C64.4387 15.5376 65.5167 15.1027 66.2148 14.2201L66.6135 13.7069V15.2578H68.5818V6.2035H66.5899V7.76078L66.1896 7.24595C65.4946 6.37778 64.4119 5.93969 63.3183 5.94289H63.3198ZM66.5914 10.5252C66.593 10.5875 66.593 10.6483 66.5914 10.7107C66.6261 11.4877 66.333 12.2599 65.7861 12.8211C65.2677 13.352 64.5522 13.6525 63.8163 13.6525H63.7028C62.9731 13.6525 62.2624 13.352 61.7471 12.8211C61.2176 12.2631 60.9292 11.5005 60.9528 10.7266C60.926 9.9608 61.216 9.19655 61.7471 8.64015C62.2766 8.09494 63.0173 7.79915 63.7658 7.81194C63.7926 7.81194 63.8194 7.81194 63.8462 7.81194C65.3228 7.81194 66.5489 9.01268 66.5914 10.5252Z"
            fill="white"
          />
          <path
            d="M75.6478 5.93341H75.6257C74.532 5.93341 73.4588 6.3619 72.7733 7.23647L72.3699 7.7545V3.01392H70.3779V15.2579H72.3463V13.7086L72.7497 14.225C73.4415 15.106 74.5037 15.5313 75.6257 15.5281H75.6431C76.8124 15.5281 77.9234 15.0245 78.6988 14.1451C79.5545 13.2162 79.9831 12.0058 79.9816 10.7396C79.9816 9.45088 79.5545 8.25494 78.7003 7.32441C77.9234 6.44025 76.8124 5.93501 75.6478 5.93501V5.93341ZM77.1969 12.8101L77.1938 12.8133C76.6249 13.3569 75.8936 13.6271 75.164 13.6271C74.4343 13.6271 73.7157 13.3585 73.1531 12.8229C72.6205 12.2617 72.3305 11.499 72.3557 10.7252C72.3289 9.95772 72.6189 9.19507 73.15 8.63867C73.6795 8.09346 74.4217 7.79767 75.1687 7.81046C75.9189 7.79927 76.658 8.08866 77.1938 8.62747C77.7248 9.18068 78.018 9.93693 77.9991 10.7076C78.0227 11.4942 77.7312 12.2617 77.1969 12.8101Z"
            fill="white"
          />
          <path
            d="M83.2725 6.20361H81.3042V15.2579H83.2725V6.20361Z"
            fill="white"
          />
          <path
            d="M87.0782 2.44629H85.1099V15.2579H87.0782V2.44629Z"
            fill="white"
          />
          <path
            d="M90.8843 6.20361H88.916V15.2579H90.8843V6.20361Z"
            fill="white"
          />
          <path
            d="M46.1089 5.8726H46.0711C44.9269 5.8678 43.8049 6.31068 43.0674 7.19644L42.8798 7.42188V6.10923H40.9478V18.98H42.8798V14.0043L43.0674 14.2266C43.8159 15.1155 44.9159 15.536 46.0585 15.5408H46.1073C47.2987 15.5408 48.4397 15.0388 49.2481 14.1562C50.1227 13.2385 50.5735 11.9658 50.5719 10.7091C50.5719 9.42204 50.1275 8.20212 49.2497 7.27639C48.4428 6.38263 47.3018 5.8726 46.1105 5.8726H46.1089ZM47.8203 12.8148L47.8172 12.818C47.242 13.3776 46.5029 13.6558 45.7653 13.6558C45.0278 13.6558 44.2887 13.376 43.7135 12.818C43.1635 12.2616 42.8593 11.491 42.8798 10.7043C42.8562 9.92408 43.154 9.15184 43.6977 8.59224C44.8513 7.47304 46.6541 7.47304 47.8046 8.59064C48.3514 9.15184 48.6493 9.92408 48.6256 10.7107C48.6493 11.4846 48.3561 12.2536 47.8219 12.8148H47.8203Z"
            fill="white"
          />
          <path
            d="M100.213 6.20347H95.714V3.72046H95.402L91.9775 7.71598V8.01177H93.7441V12.2855C93.7441 15.2562 95.4351 15.3265 96.8046 15.3265H97.8399V13.491H96.8786C95.7156 13.491 95.7046 13.0577 95.7046 12.2727V8.01177H98.8044L101.037 13.4367H102.479L100.941 17.7599H103.045L107.163 6.18748H105.064L102.738 12.7603L100.213 6.20507V6.20347Z"
            fill="white"
          />
          <path
            d="M56.6767 4.82698C57.3382 4.82698 57.8744 4.28295 57.8744 3.61185C57.8744 2.94076 57.3382 2.39673 56.6767 2.39673C56.0152 2.39673 55.479 2.94076 55.479 3.61185C55.479 4.28295 56.0152 4.82698 56.6767 4.82698Z"
            fill="white"
          />
          <path
            d="M82.2875 4.82698C82.949 4.82698 83.4852 4.28295 83.4852 3.61185C83.4852 2.94076 82.949 2.39673 82.2875 2.39673C81.6261 2.39673 81.0898 2.94076 81.0898 3.61185C81.0898 4.28295 81.6261 4.82698 82.2875 4.82698Z"
            fill="white"
          />
          <path
            d="M89.8998 4.82698C90.5613 4.82698 91.0975 4.28295 91.0975 3.61185C91.0975 2.94076 90.5613 2.39673 89.8998 2.39673C89.2384 2.39673 88.7021 2.94076 88.7021 3.61185C88.7021 4.28295 89.2384 4.82698 89.8998 4.82698Z"
            fill="white"
          />
          <path
            d="M109.894 9.11495C109.087 9.11495 108.43 8.44823 108.43 7.62962C108.43 6.81101 109.087 6.14429 109.894 6.14429C110.701 6.14429 111.358 6.81101 111.358 7.62962C111.358 8.44823 110.701 9.11495 109.894 9.11495ZM109.894 6.41609C109.235 6.41609 108.698 6.9597 108.698 7.62962C108.698 8.29954 109.234 8.84314 109.894 8.84314C110.555 8.84314 111.09 8.29954 111.09 7.62962C111.09 6.9597 110.555 6.41609 109.894 6.41609Z"
            fill="white"
          />
          <path
            d="M110.456 7.36907C110.456 7.23637 110.408 7.12605 110.311 7.03811C110.214 6.94858 110.083 6.90381 109.917 6.90381H109.44V8.33798H109.735V7.85352H109.941L110.189 8.33798H110.51L110.215 7.79117C110.376 7.71123 110.456 7.57053 110.456 7.36907ZM110.097 7.52736C110.053 7.56413 109.993 7.58172 109.917 7.58172H109.735V7.18201H109.917C110.08 7.18201 110.162 7.24916 110.162 7.38346C110.162 7.44262 110.14 7.49059 110.097 7.52736Z"
            fill="white"
          />
        </svg>
      );

    case "logo-Gray":
      return (
        <svg
          width="167"
          height="167"
          viewBox="0 0 167 167"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 16.7357C0 7.49281 7.49283 0 16.7357 0H150.264C159.507 0 167 7.49283 167 16.7357V150.264C167 159.507 159.507 167 150.264 167H16.7357C7.49281 167 0 159.507 0 150.264V16.7357Z"
            fill="#F6F6F6"
          />
          <path
            d="M29.7426 117.617H57.7321C73.66 117.617 86.5657 104.684 86.5657 88.7339V86.7647C86.6311 85.7337 87.5132 84.9428 88.5479 84.9428H119.424C129.225 84.9919 137.208 77.0661 137.257 67.2474C137.306 57.4288 129.394 49.4321 119.592 49.383H86.7618L86.5984 82.9245C86.5984 84.0209 85.7053 84.9101 84.6054 84.9101H58.5762C42.6537 84.9101 29.7426 97.8434 29.7426 113.793V117.617Z"
            fill="black"
          />
        </svg>
      );

    case "logo-light-sm":
      return (
        <svg
          width="29"
          height="29"
          viewBox="0 0 29 29"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 2.9062C0 1.30115 1.30115 0 2.9062 0H26.0938C27.6989 0 29 1.30115 29 2.9062V26.0938C29 27.6989 27.6989 29 26.0938 29H2.9062C1.30115 29 0 27.6989 0 26.0938V2.9062Z"
            fill="black"
          />
          <path
            d="M5.16479 20.4245H10.0253C12.7912 20.4245 15.0323 18.1786 15.0323 15.4089V15.0669C15.0436 14.8879 15.1968 14.7505 15.3765 14.7505H20.7381C22.4402 14.7591 23.8265 13.3827 23.835 11.6777C23.8435 9.97266 22.4696 8.58401 20.7675 8.57549H15.0663L15.038 14.4001C15.038 14.5905 14.8829 14.7449 14.6919 14.7449H10.1718C7.40685 14.7449 5.16479 16.9908 5.16479 19.7605V20.4245Z"
            fill="white"
          />
        </svg>
      );
    case "logo-light-md":
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.00855C0 1.79469 1.79469 0 4.00855 0H35.9915C38.2053 0 40 1.79469 40 4.00855V35.9915C40 38.2053 38.2053 40 35.9915 40H4.00855C1.79469 40 0 38.2053 0 35.9915V4.00855Z"
            fill="black"
          />
          <path
            d="M7.1239 28.1717H13.828C17.6431 28.1717 20.7342 25.0739 20.7342 21.2536V20.782C20.7499 20.535 20.9612 20.3456 21.209 20.3456H28.6044C30.9521 20.3573 32.8642 18.4589 32.8759 16.1072C32.8877 13.7554 30.9925 11.84 28.6448 11.8283H20.7812L20.7421 19.8622C20.7421 20.1248 20.5282 20.3377 20.2647 20.3377H14.0302C10.2164 20.3377 7.1239 23.4355 7.1239 27.2559V28.1717Z"
            fill="white"
          />
        </svg>
      );

    case "explore-logo":
      return (
        <svg
          width="30"
          height="20"
          viewBox="0 0 30 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0.143555 19.4284H7.87875C12.2806 19.4284 15.8472 15.8542 15.8472 11.4463V10.9021C15.8653 10.6172 16.1091 10.3986 16.395 10.3986H24.9278C27.6366 10.4122 29.8428 8.22178 29.8563 5.50831C29.8699 2.79483 27.6833 0.584856 24.9744 0.571289H15.9014L15.8562 9.84082C15.8562 10.1438 15.6094 10.3895 15.3054 10.3895H8.11201C3.71168 10.3895 0.143555 13.9638 0.143555 18.3717V19.4284Z"
            fill="white"
          />
        </svg>
      );

    case "white-background-sm":
      return (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1.71533 38.1426H13.3181C19.9209 38.1426 25.2708 32.7813 25.2708 26.1694V25.3531C25.2979 24.9258 25.6636 24.5979 26.0925 24.5979H38.8917C42.9549 24.6182 46.2642 21.3327 46.2845 17.2625C46.3048 13.1922 43.0249 9.87728 38.9617 9.85693H25.3521L25.2844 23.7612C25.2844 24.2157 24.9141 24.5843 24.4582 24.5843H13.668C7.06752 24.5843 1.71533 29.9457 1.71533 36.5575V38.1426Z"
            fill="black"
          />
        </svg>
      );

    default:
      return (
        <svg
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 4.00855C0 1.79469 1.79469 0 4.00855 0H35.9915C38.2053 0 40 1.79469 40 4.00855V35.9915C40 38.2053 38.2053 40 35.9915 40H4.00855C1.79469 40 0 38.2053 0 35.9915V4.00855Z"
            fill="black"
          />
          <path
            d="M7.1239 28.1717H13.828C17.6431 28.1717 20.7342 25.0739 20.7342 21.2536V20.782C20.7499 20.535 20.9612 20.3456 21.209 20.3456H28.6044C30.9521 20.3573 32.8642 18.4589 32.8759 16.1072C32.8877 13.7554 30.9925 11.84 28.6448 11.8283H20.7812L20.7421 19.8622C20.7421 20.1248 20.5282 20.3377 20.2647 20.3377H14.0302C10.2164 20.3377 7.1239 23.4355 7.1239 27.2559V28.1717Z"
            fill="white"
          />
        </svg>
      );
  }
};
