import { useAction, useSelector } from "@preact-hooks/unistore";
import { actions } from "store";

interface CurrentUrlHook {
  currentUrl: string | null;
  currentURL: URL;
  setCurrentUrlAction(url: string | null): void;
}

export const useCurrentUrl = (): CurrentUrlHook => {
  const setCurrentUrlAct = useAction(actions.setCurrentUrl);
  const { currentUrl } = useSelector("currentUrl");
  const setCurrentUrlAction = (url: string | null) => setCurrentUrlAct(url);
  let currentURL = new URL("http://localhost/");
  if (currentUrl) {
    currentURL = new URL(
      currentUrl && currentUrl?.includes("http")
        ? currentUrl
        : `http://localhost/${currentUrl}`
    );
  }
  return { currentURL, currentUrl, setCurrentUrlAction };
};
