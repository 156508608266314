import { API_BASE, APIResponse, callApi } from "./api";
import { generateHMAC } from "./hmac";
import { GoogleProfile, User } from "models/user";

// Register a new user and get back user information and auth tokens
export async function signup(opts: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  tos?: boolean;
  confirmSuccessUrl: string;
}): Promise<APIResponse<User>> {
  try {
    const resp = await callApi(`${API_BASE}/auth`, {
      method: "POST",
      body: opts,
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

/*
When a session is created, the response headers contain the a JWT token that should be sent
with the next request, in the same format it was recieved.
This token is on the "Authorization" header.
*/
export async function signin(opts: {
  email: string;
  password: string;
  rememberMe: boolean;
}): Promise<APIResponse<User>> {
  try {
    const resp = await callApi(`${API_BASE}/auth/sign_in`, {
      method: "POST",
      body: opts,
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

export async function signout(): Promise<APIResponse<User>> {
  try {
    const resp = await callApi(`${API_BASE}/auth/sign_out`);
    return resp;
  } catch (e) {
    throw e;
  }
}

export async function googleSignin(
  opts: GoogleProfile
): Promise<APIResponse<User>> {
  try {
    const resp = await callApi(`${API_BASE}/auth/google`, {
      method: "POST",
      body: opts,
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

/* By passing an email address to this endpoint, the password reset flow for that user is
triggered
*/
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function requestResetPassword(
  email: string
): Promise<APIResponse<any>> {
  const opts = {
    email,
    redirectUrl: "/users/password/new",
  };
  try {
    return await callApi(`${API_BASE}/auth/password`, {
      method: "POST",
      body: opts,
    });
  } catch (e) {
    throw e;
  }
}

// Updates the password
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function resetPassword(opts: {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
}): Promise<APIResponse<any>> {
  const hmac = await generateHMAC();
  try {
    return await callApi(`${API_BASE}/users/reset_password`, {
      method: "POST",
      body: opts,
      headers: hmac,
    });
  } catch (e) {
    throw e;
  }
}

export async function verifyEmail({
  email,
}: {
  email: string;
}): Promise<APIResponse<any>> {
  const hmac = await generateHMAC();
  try {
    return await callApi(
      `${API_BASE}/users/verify_email?email=${encodeURIComponent(email)}`,
      {
        headers: hmac,
      }
    );
  } catch (e) {
    throw e;
  }
}

export async function checkEmailExists({
  email,
}: {
  email: string;
}): Promise<APIResponse<any>> {
  const hmac = await generateHMAC();
  try {
    return await callApi(
      `${API_BASE}/users/email_exists?email=${encodeURIComponent(email)}`,
      {
        headers: hmac,
      }
    );
  } catch (e) {
    throw e;
  }
}
