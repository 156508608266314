import { FunctionalComponent, h, Fragment } from "preact";
import { styled } from "linaria/react";
import Button from "components/shared/Button";
import Colors from "style/colors";
import useSubscriptionGroup from "hooks/subscriptionGroupHooks";
import mq from "style/breakpoints";
import { useEffect, useState } from "react";
import { validateEmail } from "util/validationUtil";
import { DisplayOverlay } from "style/layout";
import Icon, { IconTypes } from "components/shared/Icon";
import { RenderLogo } from "components/layout/RenderLogo";
import { useRef } from "preact/hooks";
import { downloadCSV, transfCSV } from "util/team";
import { RenderIcon } from "components/layout/RenderIcon";
import { SubscriptionGroupInvite } from "models/subscriptionGroup";
import { orgPlanPrice } from "util/subscriptionUtils";
import InviteManuallyModal from "./InviteManuallyModal";
import {
  ArrowPrice,
  BackBillingUpdate,
  BillingUpdateButtons,
  BillingUpdateContainer,
  BillingUpdateContent,
  Close,
  ContactOurTeam,
  Container,
  Divider,
  PriceContainer,
  Warning,
} from "style/team";

const InvitationSentContainer = styled(Container)`
  > div {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    ${mq.minSm} {
      padding: 40px;
    }

    h1 {
      color: #000000;
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
    }

    p {
      color: #444444;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    > button {
      width: 100%;
      height: 56px;
      border-radius: 9999px;
      span {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
`;

const MembersInvite = styled.div<{ showWarning?: boolean }>`
  border-radius: 8px;
  border: 1px solid #8e8e93;
  padding: 16px;
  margin-bottom: 40px;
  max-height: ${({ showWarning }) => (showWarning ? "30vh" : "45vh")};
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  @-moz-document url-prefix() {
    scrollbar-width: none;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #333333;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      opacity: 0.34;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > button {
      background: transparent;
      padding: 0;

      :hover:active {
        background: transparent;
      }
    }
  }
`;

const UploadCSVContainer = styled(Container)`
  h1 {
    color: #000000;
    font-family: "Neusa Next Pro";
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 4px;
  }
`;

const UploadCSVContent = styled.div<{
  error: boolean;
  showWarning: boolean;
  showContactOurTeam: boolean;
}>`
  > div {
    padding: 40px;
    height: 100%;
    padding: 40px;

    .upload-content {
      position: relative;
      height: 200px;
      border-radius: 8px;
      border: 1px solid #8e8e93;
      margin-bottom: 24px;
    }

    .drop-zone {
      text-align: center;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;

      p {
        color: #000000;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
        opacity: 0.34;
      }
    }

    > button {
      width: 100%;
      height: 56px;
      background: ${Colors.bgDark0};
      border-radius: 9999px;
      border: none;
      cursor: ${({ showContactOurTeam }) =>
        showContactOurTeam ? "no-drop" : "pointer"};
      display: inline-flex;

      > div > span {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }

      :hover {
        opacity: 0.9;
      }

      :disabled,
      &.inactive {
        color: ${Colors.bgLight2} !important;
        background-color: ${Colors.bgGrey2} !important;
      }
    }

    > p {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      color: #000000;
      margin-bottom: ${({ showWarning }) => (showWarning ? "24px" : "40px")};

      span {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: #0d41e1;
      }
    }

    > p {
      font-size: 18px;
      font-weight: 500;
      line-height: 28px;
      color: #000000;
      margin-bottom: ${({ showWarning }) => (showWarning ? "24px" : "40px")};

      span {
        font-size: 18px;
        font-weight: 700;
        line-height: 28px;
        color: #0d41e1;
      }
    }
  }
`;

const ButtonsUploadCSV = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;

  > div {
    display: flex;
    align-items: center;
    background: transparent;
    padding: 0;
    color: #000000;
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;

    svg {
      margin-right: 12px;
    }
  }
`;

interface OrganizationProps {
  onClose?: () => void;
  type?: string;
  setRefetch?: () => void;
  setMemberModalContent?: (type: string) => void;
  memberLimit: number;
  onDelete?: () => void;
  isLoading?: boolean;
  subscriptionGroupInvites?: any;
}

const MemberModalContent: FunctionalComponent<OrganizationProps> = ({
  onClose,
  type,
  setRefetch,
  setMemberModalContent,
  memberLimit,
  onDelete,
  isLoading,
  subscriptionGroupInvites,
}: OrganizationProps) => {
  const { X, ArrowRight, AngleLeft } = IconTypes;
  const { getSubscriptionGroupAdmin } = useSubscriptionGroup();
  const { createSubscriptionGroupInvite } = useSubscriptionGroup();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [memberList, setMemberList] = useState<string[]>([]);
  const [emailList, setEmailList] = useState<string[]>([]);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isVisibleModalInvitationSent, setIsVisibleModalInvitationSent] =
    useState(false);
  const [fileName, setFileName] = useState<string[]>([]);
  const [emailListUpload, setEmailListUpload] = useState<any>([]);
  const [returnModal, setReturnModal] = useState("");
  const inviteSentModal = type === "invite-sent-modal";
  const deleteModal = type === "delete-modal";
  const limitMemberModal = type === "limit-member-modal";
  const uploadCSVModal = type === "upload-csv-modal";
  const billingCount = subscriptionGroupInvites?.billingCount || 0;
  const activeCount = subscriptionGroupInvites?.activeCount || 0;
  const pendingCount = subscriptionGroupInvites?.pendingCount || 0;
  const [billingUpdate, setBillingUpdate] = useState(false);
  const [isLoadingBilling, setIsLoadingBilling] = useState(false);

  const plan = getSubscriptionGroupAdmin(
    subscriptionGroupInvites as SubscriptionGroupInvite
  )?.plan;

  const preventDuplicationMemberList = memberList.filter(
    (value, index, arr) => arr.indexOf(value) === index
  );

  const preventDuplicationListUpload = emailListUpload
    .filter((value, index, arr) => arr.indexOf(value) === index)
    .filter((email) => !preventDuplicationMemberList?.includes(email));

  const totalMembers =
    activeCount +
    pendingCount +
    (uploadCSVModal ? preventDuplicationListUpload?.length : emailList?.length);

  const extraSeats = billingCount - totalMembers;

  const showWarning =
    billingCount - (activeCount + pendingCount) <= 0 ||
    (extraSeats <= 0 &&
      (emailList?.length || preventDuplicationListUpload?.length));

  const showContactOurTeam = totalMembers > memberLimit;

  const sendInvite = async (billingUpdate?: string) => {
    setLoading(true);
    setIsLoadingBilling(true);

    if (billingUpdate) {
      setBillingUpdate(true);
      setIsLoadingBilling(false);
      setLoading(false);
      return;
    }

    if (billingUpdate) {
      setBillingUpdate(true);
      setIsLoadingBilling(false);
      setLoading(false);
      return;
    }

    const result = await createSubscriptionGroupInvite({
      emails: uploadCSVModal ? preventDuplicationListUpload : emailList,
    });

    if (result) {
      setLoading(false);
      setBillingUpdate(false);
      setIsVisibleModalInvitationSent(true);
      setEmailListUpload([]);
      setIsLoadingBilling(false);

      setTimeout(() => {
        setRefetch?.();
      }, 1000);
    } else {
      setLoading(false);
      setIsLoadingBilling(false);
      setError(true);
    }
  };

  const openIntercomChat = (e) => {
    e.preventDefault();
    if (typeof window !== "undefined" && window.Intercom) {
      window.Intercom("show");
      if (!!emailList?.length && returnModal === "member-modal") {
        setMemberModalContent?.(returnModal);
        setReturnModal("");
        setEmailListUpload([]);
      } else if (
        !!preventDuplicationListUpload?.length &&
        returnModal === "upload-csv-modal"
      ) {
        setMemberModalContent?.(returnModal);
        setReturnModal("");
        setEmailList([]);
      } else {
        setEmailListUpload([]);
        onClose?.();
        setEmailList([]);
      }
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files.length > 0) {
      setFileName([...fileName, files[0]?.name || "file"]);
      const reader = new FileReader();
      reader.onload = (event) => {
        const text = event?.target?.result;
        if (typeof text === "string") {
          const data = transfCSV(text);
          const list: string[] = [];
          data?.map((d) => !!d.email?.length && list.push(d.email));
          setEmailListUpload([...emailListUpload, ...list]);
        }
      };
      reader.readAsText(files[0]);
    }
  };

  const fileInputRef = useRef<any>(null);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0];
    if (file) {
      if (fileName?.includes(file.name)) {
        return;
      }

      setFileName([...fileName, file.name]);
      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e?.target?.result;
        if (typeof text === "string") {
          const data = transfCSV(text);
          const list: string[] = [];
          data?.map((d) => !!d.email?.length && list.push(d.email));
          setEmailListUpload([...emailListUpload, ...list]);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleButtonClick = () => {
    if (showContactOurTeam) return;

    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const addEmail = (e: h.JSX.TargetedEvent<HTMLFormElement, Event>) => {
    e.preventDefault();

    if (!email?.length || showContactOurTeam) return;

    const existingEmailInList = emailList.some(
      (addedEmail) => addedEmail.toLowerCase() === email.toLowerCase()
    );
    const existingEmailInMemberList = preventDuplicationMemberList.some(
      (memberEmail) => memberEmail.toLowerCase() === email.toLowerCase()
    );

    if (!validateEmail(email)) {
      setError(true);
      setEmailError("Please enter a valid email address.");
      return;
    }

    if (existingEmailInList || existingEmailInMemberList) {
      setError(true);
      setEmailError(`${email} has already been added.`);
      return;
    }

    setEmailList([...emailList, email]);
    setEmail("");
    setError(false);
    setEmailError("");
  };

  useEffect(() => {
    subscriptionGroupInvites &&
      subscriptionGroupInvites?.members?.data?.map((member) =>
        setMemberList((prevState) => [...prevState, member.email])
      );
  }, [subscriptionGroupInvites]);

  return (
    <Fragment>
      {isVisibleModalInvitationSent ||
      inviteSentModal ||
      deleteModal ||
      limitMemberModal ||
      billingUpdate ? (
        <InvitationSentContainer>
          <div>
            {RenderLogo("white-background-sm")}
            <h1>
              {billingUpdate
                ? "Billing Update"
                : limitMemberModal
                ? "Oops It looks like you’ve hit the limit"
                : deleteModal
                ? "Are you sure you want to Delete this User?"
                : "Invite Sent"}
            </h1>
            <p>
              {billingUpdate
                ? "Your team is growing! We’ve updated the monthly price according to the amount of members you’ve added."
                : deleteModal
                ? "This member will no longer have access to pliability."
                : limitMemberModal
                ? "If you want to add more members you are going to need a custom plan. Our team is happy to help you out."
                : "Your members will receive an email shortly to join."}
            </p>
            {billingUpdate && (
              <BackBillingUpdate
                onClick={() => {
                  setBillingUpdate(false);
                }}
              >
                <Icon name={AngleLeft} />
              </BackBillingUpdate>
            )}
            {billingUpdate ? (
              <BillingUpdateContainer>
                <BillingUpdateContent>
                  <h3>Updated Monthly Price</h3>
                  <div>
                    <PriceContainer>
                      <h4>
                        {"$"}
                        {plan ? orgPlanPrice(billingCount, plan) : "0.00"}
                      </h4>
                      <span>Per {plan?.planIntervalUnit || ""}</span>
                    </PriceContainer>
                    <ArrowPrice>
                      <Icon name={ArrowRight} />
                    </ArrowPrice>
                    <PriceContainer>
                      <h4>
                        {"$"}
                        {plan ? orgPlanPrice(totalMembers || 0, plan) : "0.00"}
                      </h4>
                      <span>Per {plan?.planIntervalUnit || ""}</span>
                    </PriceContainer>
                  </div>
                </BillingUpdateContent>
                <BillingUpdateButtons>
                  <Button
                    size="S"
                    text={"Confirm"}
                    handleClick={(e) => {
                      sendInvite();
                    }}
                    loading={isLoadingBilling}
                  />
                  <Button
                    size="S"
                    text={"Cancel"}
                    handleClick={(e) => {
                      setEmailListUpload([]);
                      onClose?.();
                      setEmailList([]);
                    }}
                  />
                </BillingUpdateButtons>
              </BillingUpdateContainer>
            ) : (
              <Button
                size="S"
                text={
                  deleteModal
                    ? "Delete"
                    : limitMemberModal
                    ? "Contact Our Support Team"
                    : "Done"
                }
                handleClick={(e) => {
                  if (deleteModal) {
                    onDelete?.();
                  } else if (limitMemberModal) {
                    openIntercomChat(e);
                  } else {
                    onClose?.();
                  }
                }}
                loading={isLoading}
              />
            )}
          </div>
        </InvitationSentContainer>
      ) : uploadCSVModal ? (
        <UploadCSVContainer>
          <UploadCSVContent
            error={error}
            showWarning={showWarning}
            showContactOurTeam={showContactOurTeam}
          >
            <div>
              <h1>Invite</h1>
              <p>
                <span>{extraSeats >= 0 ? extraSeats : 0} seats</span> remaining
                before additional billing cost.
              </p>
              {(showWarning && inviteSentModal) ||
                (uploadCSVModal &&
                  showWarning &&
                  totalMembers <= memberLimit && (
                    <Warning showContactOurTeam={showContactOurTeam}>
                      {RenderIcon("warning")}
                      <h3>
                        Current prepaid member plan has reached its maximum
                        capacity. Adding any additional members will incur an
                        extra fee.
                      </h3>
                    </Warning>
                  ))}
              {showContactOurTeam && (
                <ContactOurTeam>
                  {RenderIcon("warning-red")}
                  <h3>
                    If you want to add more members you are going to need a
                    custom plan. Our team is happy to help you out.{" "}
                    <span
                      onClick={() => {
                        if (typeof window !== "undefined" && window.Intercom) {
                          window.Intercom("show");
                        }
                      }}
                    >
                      Contact Our Team{" "}
                    </span>
                  </h3>
                </ContactOurTeam>
              )}
              <Close
                onClick={() => {
                  setEmailListUpload([]);
                  setEmailList([]);
                  onClose?.();
                }}
              >
                <Icon name={X} />
              </Close>
              {!emailListUpload?.length ? (
                <div
                  className="upload-content"
                  onClick={(e) => e.stopPropagation()}
                >
                  <div
                    className="drop-zone"
                    onDragOver={handleDragOver}
                    onDrop={handleDrop}
                  >
                    <p>
                      Drop files here
                      <br /> or use upload button.
                    </p>
                  </div>
                </div>
              ) : (
                <Fragment>
                  <MembersInvite>
                    {fileName?.map((name, index) => (
                      <Fragment key={index}>
                        <div>
                          <span>{name}</span>
                          <Button
                            size="S"
                            text="Remove"
                            handleClick={() => {
                              const filterFileName = fileName?.filter(
                                (na) => na !== name
                              );
                              setFileName(filterFileName);
                              !filterFileName?.length && setEmailListUpload([]);
                            }}
                          />
                        </div>
                        {fileName?.length > 0 &&
                          index !== fileName?.length - 1 && <Divider />}
                      </Fragment>
                    ))}
                  </MembersInvite>
                </Fragment>
              )}
              <div>
                <input
                  ref={fileInputRef}
                  type="file"
                  accept=".csv"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
                <ButtonsUploadCSV>
                  <div
                    onClick={handleButtonClick}
                    style={{
                      cursor: showContactOurTeam ? "no-drop" : "pointer",
                    }}
                  >
                    {RenderIcon("upload")}
                    Upload a CSV File
                  </div>
                  <div
                    onClick={() => {
                      downloadCSV(
                        [
                          { email: "test@test.com" },
                          { email: "test@test.com" },
                        ],
                        "template"
                      );
                    }}
                  >
                    Download Template
                  </div>
                </ButtonsUploadCSV>
              </div>
              <Button
                size="S"
                text={totalMembers > billingCount ? "Next" : "Invite members"}
                handleClick={() => {
                  !showContactOurTeam &&
                    sendInvite(
                      totalMembers > billingCount && !showContactOurTeam
                        ? "billing-update"
                        : ""
                    );
                }}
                loading={loading}
                disabled={!emailListUpload?.length || showContactOurTeam}
                unwrapped={true}
              />
            </div>
          </UploadCSVContent>
        </UploadCSVContainer>
      ) : (
        <InviteManuallyModal
          onClose={onClose}
          setEmailListUpload={setEmailListUpload}
          setEmailList={setEmailList}
          showWarning={showWarning}
          extraSeats={extraSeats}
          showContactOurTeam={showContactOurTeam}
          email={email}
          addEmail={addEmail}
          emailError={emailError}
          setEmail={setEmail}
          setError={setError}
          setEmailError={setEmailError}
          emailList={emailList}
          totalMembers={totalMembers}
          billingCount={billingCount}
          loading={loading}
          sendInvite={sendInvite}
        />
      )}
      <DisplayOverlay
        className="overlay"
        background="#000000"
        style={{ opacity: 0.7, zIndex: 10004 }}
        onClick={() => {
          if (!!emailList?.length && returnModal === "member-modal") {
            setMemberModalContent?.(returnModal);
            setReturnModal("");
            setEmailListUpload([]);
          } else if (
            !!emailListUpload?.length &&
            returnModal === "upload-csv-modal"
          ) {
            setMemberModalContent?.(returnModal);
            setReturnModal("");
            setEmailList([]);
          } else {
            setEmailListUpload([]);
            onClose?.();
            setEmailList([]);
          }
        }}
      />
    </Fragment>
  );
};

export default MemberModalContent;
