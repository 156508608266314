import { FunctionalComponent, h } from "preact";
import Icon, { IconTypes } from "components/shared/Icon";
import { styled } from "linaria/react";
import Colors from "style/colors";

interface SearchDropdownProps {
  value: string;
  onChange: (e: h.JSX.TargetedEvent<HTMLSelectElement>) => void;
  label: string;
  options: Array<{ value: string; label: string }>;
}

const SearchDropdown: FunctionalComponent<SearchDropdownProps> = ({
  value,
  onChange,
  label,
  options,
}) => {
  const handleClick = (e) => {
    const selectElement = e.currentTarget.querySelector("select");
    if (selectElement) {
      selectElement.focus();
      selectElement.click();
    }
  };

  return (
    <Dropdown>
      <DropdownLabel>{label}:</DropdownLabel>
      <select value={value} onChange={onChange}>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <IconButton onClick={handleClick}>
        <Icon name={IconTypes.AngleDown} size="S" color={"black"} />
      </IconButton>
    </Dropdown>
  );
};

export default SearchDropdown;

const DropdownLabel = styled.label`
  font-weight: 600;
  font-size: 14px;
  color: ${Colors.txtDark};
  margin-right: 8px;
`;

const Dropdown = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 32px;
  cursor: pointer;

  select {
    border: none;
    background: transparent;
    font-weight: 600;
    font-size: 14px;
    color: ${Colors.txtDark};
    appearance: none;
    cursor: pointer;
    width: auto;
    min-width: 20px;
    padding-right: 24px; /* Adjust padding to make space for the icon */
  }

  select:focus {
    outline: none;
  }

  select option {
    background: ${Colors.bgLight2};
    color: ${Colors.txtDark};
    padding: 8px;
  }
`;

const IconButton = styled.button`
  position: absolute;
  right: 6px;
  background: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;
