import { css } from "linaria";
import Colors from "style/colors";
import mq from "style/breakpoints";

export const globals = css`
  overflow: visible;
  input[type="text"],
  input[type="button"] {
    -webkit-appearance: none;
    -webkit-border-radius: 0;
    border-radius: 0;
  }
  :global() {
    * {
      box-sizing: border-box;
    }

    html {
      overflow-y: overlay;
    }

    height: 100%;
    input {
      outline: none;
    }

    /* ICONS CONFIGURATION */
    @font-face {
      font-family: "icomoon";
      src: url("/assets/iconFont/icomoon.woff?m2grkq");
      src: url("/assets/iconFont/icomoon.eot?m2grkq#iefix")
          format("embedded-opentype"),
        url("/assets/iconFont/icomoon.ttf?m2grkq") format("truetype"),
        url("/assets/iconFont/icomoon.svg?m2grkq#icomoon") format("svg");
      font-weight: normal;
      font-style: normal;
      font-display: block;
    }

    [class^="icon-"],
    [class*=" icon-"] {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: "icomoon" !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      color: inherit;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

    .icon-Activity:before {
      content: "\\e900";
    }
    .icon-Airplay:before {
      content: "\\e901";
    }
    .icon-Android:before {
      content: "\\e902";
    }
    .icon-Angle--Down:before {
      content: "\\e903";
    }
    .icon-Angle--Left:before {
      content: "\\e904";
    }
    .icon-Angle--Right:before {
      content: "\\e905";
    }
    .icon-Angle--Up:before {
      content: "\\e906";
    }
    .icon-Apple:before {
      content: "\\e907";
    }
    .icon-Arrow--Down:before {
      content: "\\e908";
    }
    .icon-Arrow--Left:before {
      content: "\\e909";
    }
    .icon-Arrow--Right:before {
      content: "\\e90a";
    }
    .icon-Arrow--Up:before {
      content: "\\e90b";
    }
    .icon-Caret--Down:before {
      content: "\\e90c";
    }
    .icon-Caret--Left:before {
      content: "\\e90d";
    }
    .icon-Caret--Right:before {
      content: "\\e90e";
    }
    .icon-Caret--Up:before {
      content: "\\e90f";
    }
    .icon-Cast:before {
      content: "\\e910";
    }
    .icon-Collapse:before {
      content: "\\e911";
    }
    .icon-Completion--Done .path1:before {
      content: "\\e912";
      color: rgb(255, 179, 0);
    }
    .icon-Completion--Done .path2:before {
      content: "\\e913";
      margin-left: -1em;
      color: rgb(0, 0, 0);
    }
    .icon-Completion--TBD:before {
      content: "\\e914";
    }
    .icon-Download-Progress:before {
      content: "\\e915";
      color: #ffb300;
    }
    .icon-Download--Active .path1:before {
      content: "\\e916";
      color: rgb(255, 179, 0);
    }
    .icon-Download--Active .path2:before {
      content: "\\e917";
      margin-left: -1em;
      color: rgb(0, 0, 0);
    }
    .icon-Download--Active .path3:before {
      content: "\\e918";
      margin-left: -1em;
      color: rgb(0, 0, 0);
    }
    .icon-Download:before {
      content: "\\e919";
    }
    .icon-Error-Alert .path1:before {
      content: "\\e91a";
      color: rgb(255, 180, 0);
    }
    .icon-Error-Alert .path2:before {
      content: "\\e91b";
      margin-left: -1em;
      color: rgb(255, 179, 0);
    }
    .icon-Expand:before {
      content: "\\e91c";
    }
    .icon-Explore:before {
      content: "\\e91d";
    }
    .icon-External-Link:before {
      content: "\\e91e";
    }
    .icon-Eye--Off:before {
      content: "\\e91f";
    }
    .icon-Eye--On:before {
      content: "\\e920";
    }
    .icon-Fast-Forward:before {
      content: "\\e921";
    }
    .icon-Favorite--Filled:before {
      content: "\\e922";
      color: #ffffff;
    }
    .icon-Favorite:before {
      content: "\\e923";
      color: #ffffff;
    }
    .icon-Filter--Filled:before {
      content: "\\e924";
    }
    .icon-Filter:before {
      content: "\\e925";
    }
    .icon-Home:before {
      content: "\\e926";
    }
    .icon-Leave-Path:before {
      content: "\\e927";
    }
    .icon-Loading:before {
      content: "\\e928";
    }
    .icon-Menu:before {
      content: "\\e929";
    }
    .icon-More:before {
      content: "\\e92a";
    }
    .icon-Paths:before {
      content: "\\e92b";
    }
    .icon-Pause:before {
      content: "\\e92c";
    }
    .icon-Play:before {
      content: "\\e92d";
    }
    .icon-Profile:before {
      content: "\\e92e";
    }
    .icon-Reset-Progress:before {
      content: "\\e92f";
    }
    .icon-Rewind:before {
      content: "\\e930";
    }
    .icon-Search:before {
      content: "\\e931";
    }
    .icon-Settings:before {
      content: "\\e932";
    }
    .icon-Share:before {
      content: "\\e933";
    }
    .icon-Shop:before {
      content: "\\e934";
    }
    .icon-Shorter:before {
      content: "\\e935";
    }
    .icon-Subtract:before {
      content: "\\e936";
    }
    .icon-Success:before {
      content: "\\e937";
    }
    .icon-Success:after {
      content: "\\e937";
    }
    .icon-Support:before {
      content: "\\e938";
    }
    .icon-Trash-2:before {
      content: "\\e939";
      color: #ffb300;
    }
    .icon-Trash:before {
      content: "\\e93a";
    }
    .icon-Upload:before {
      content: "\\e93b";
      color: #ffb300;
    }
    .icon-Warning:before {
      content: "\\e93c";
    }
    .icon-X:before {
      content: "\\e93d";
    }
    .icon-Zoom-In:before {
      content: "\\e93e";
    }
    .icon-Zoom-Out:before {
      content: "\\e93f";
    }

    .fa-times span:before {
      content: "\\e93d" !important;
      color: #ffffff !important;
    }

    /* FONTS CONFIGURATION */
    @font-face {
      font-family: "Roboto";
      src: url("https://static.romwod.com/fonts/roboto/roboto-regular-webfont.woff2")
          format("woff2"),
        url("https://static.romwod.com/fonts/roboto/roboto-regular-webfont.woff")
          format("woff"),
        url("https://static.romwod.com/fonts/roboto/roboto-regular-webfont.ttf")
          format("truetype"),
        url("https://static.romwod.com/fonts/roboto/roboto-regular-webfont.svg")
          format("svg");
      font-weight: normal;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Roboto";
      src: url("https://static.romwod.com/fonts/roboto/roboto-medium-webfont.woff2")
          format("woff2"),
        url("https://static.romwod.com/fonts/roboto/roboto-medium-webfont.woff")
          format("woff"),
        url("https://static.romwod.com/fonts/roboto/roboto-medium-webfont.ttf")
          format("truetype"),
        url("https://static.romwod.com/fonts/roboto/roboto-medium-webfont.svg")
          format("svg");
      font-weight: 500;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Roboto";
      src: url("https://static.romwod.com/fonts/roboto/roboto-bold-webfont.woff2")
          format("woff2"),
        url("https://static.romwod.com/fonts/roboto/roboto-bold-webfont.woff")
          format("woff"),
        url("https://static.romwod.com/fonts/roboto/roboto-bold-webfont.ttf")
          format("truetype"),
        url("https://static.romwod.com/fonts/roboto/roboto-bold-webfont.svg")
          format("svg");
      font-weight: bold;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Tungsten";
      src: url("https://static.romwod.com/fonts/tungsten/woff/Tungsten-Medium_Web.woff")
        format("woff");
      font-weight: normal;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Tungsten";
      src: url("https://static.romwod.com/fonts/tungsten/woff/Tungsten-Semibold_Web.woff")
        format("woff");
      font-weight: 600;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Tungsten";
      src: url("https://static.romwod.com/fonts/tungsten/woff/Tungsten-Bold_Web.woff")
        format("woff");
      font-weight: bold;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Gilroy";
      src: url("https://media.pliability.com/raw/upload/v1649943288/brand/fonts/gilroy-regular.otf")
        format("opentype");
      font-weight: normal;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Gilroy";
      src: url("https://media.pliability.com/raw/upload/v1649943288/brand/fonts/gilroy-semibold.otf")
        format("opentype");
      font-weight: 600;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Gilroy";
      src: url("https://media.pliability.com/raw/upload/v1649943288/brand/fonts/gilroy-bold.otf")
        format("opentype");
      font-weight: bold;
      font-style: normal;
      font-display: fallback;
    }

    @font-face {
      font-family: "Neusa Next Pro";
      src: url("https://media.pliability.com/raw/upload/v1649943296/brand/fonts/neusanextpro-widebold.otf")
        format("opentype");
      font-weight: 700;
      font-style: normal;
      font-display: fallback;
    }

    background-color: ${Colors.bgLight2};
    color: ${Colors.txtDark};
    font-family: "Gilroy", sans-serif;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: "Gilroy";
      font-style: normal;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 120%;
      letter-spacing: 0.02em;
      color: ${Colors.txtDark};
      margin: 0;
    }
    h1 {
      font-size: 3rem;
      font-weight: 600;
    }
    h2 {
      font-size: 2rem;
      font-weight: bold;
    }
    h3 {
      font-size: 1.5rem;
      font-weight: 600;
    }
    h4 {
      font-size: 1.125rem;
      font-weight: 600;
    }
    h5 {
      font-size: 1rem;
      font-weight: 500;
    }
    h6 {
      font-size: 0.875rem;
      font-weight: 500;
    }

    p {
      margin: 0;
    }

    span,
    div,
    p {
      font-family: "Gilroy";
      font-style: normal;
      font-weight: 600;
    }

    pre {
      margin: 1.25rem;
      padding: 0.5rem;
      color: ${Colors.txtLight};
      background-color: ${Colors.bgDark2};
      white-space: pre;
      text-shadow: 0 1px 0 #000;
      border-radius: 5px;
      border-bottom: 1px solid ${Colors.primary};
      box-shadow: 0 1px 5px rgba(0, 0, 0, 0.4) inset,
        0 0 20px rgba(0, 0, 0, 0.2) inset;
    }

    /* ANCHOR */
    a {
      color: inherit;
      line-height: inherit;
      text-decoration: none;
    }

    ${mq.xs}, ${mq.sm} {
      body.modal-open-mobileDownloadApp {
        overflow: hidden;
      }
    }
  }
`;

// BODY TEXT

const gilroy = `
  font-family: "Gilroy";
  font-style: normal;
  color: ${Colors.bgDark0};
`;

const gilroyBold = `
  ${gilroy}
  font-weight: bold;
  line-height: 120%;
`;

const gilroyRegular = `
  ${gilroy}
  font-weight: normal;
  line-height: 140%;
`;

export const textXL = `
  ${gilroyBold}
  font-size: 2rem;
`;

export const textBoldL = `
  ${gilroyBold}
  font-size: 1.5rem;
`;

export const textRegularL = `
  ${gilroyRegular}
  font-size: 1.5rem;
`;

export const textBoldM = `
  ${gilroyBold}
  font-size: 1rem;
`;

export const textRegularM = `
  ${gilroyRegular}
  font-size: 1rem;
`;

export const textBoldS = `
  ${gilroyBold}
  font-size: 0.875rem;
`;

export const textRegularS = `
   ${gilroyRegular}
  font-size: 0.875rem;
`;

export const textBoldXS = `
   ${gilroyBold}
  font-size: 0.75rem;
  text-transform: uppercase;
`;

export const textRegularXS = `
   ${gilroyRegular}
  font-size: 0.75rem;
`;

// ACTION TEXT

const gilroyActionBold = `
  ${gilroy}
  font-weight: bold;
  line-height: 120%;
`;

const gilroyAction500 = `
  ${gilroy}
  font-weight: 500;
  line-height: 120%;
`;

export const textActionL = `
   ${gilroyActionBold}
  font-size: 1.5rem;
`;

export const textActionM = `
   ${gilroyAction500}
  font-size: 1rem;
`;

export const textActionS = `
   ${gilroyAction500}
  font-size: 0.875rem;
`;
