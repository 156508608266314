import { API_BASE, APIResponse, callApi } from "./api";
import { User, PortalSession } from "models/user";

export interface UserUpdateBody {
  firstName?: string;
  lastName?: string;
  unconfirmedEmail?: string;
  gender?: "male" | "female" | "other";
  userTimezone?: string;
  birthDate?: string;
  currentPassword?: string;
  password?: string;
  passwordConfirmation?: string;
  base64Avatar?: string;
  companyRole?: string;
}

// GET user
export async function getUser(): Promise<APIResponse<User>> {
  try {
    const resp = await callApi(`${API_BASE}/current_user`);
    return resp;
  } catch (e) {
    throw e;
  }
}

// Edit user
export async function editUser(
  opts: UserUpdateBody
): Promise<APIResponse<User>> {
  try {
    const resp = await callApi(`${API_BASE}/current_user`, {
      method: "PUT",
      body: opts,
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

// Delete user's current avatar
export async function deleteCurrentAvatar(): Promise<void> {
  try {
    await callApi(`${API_BASE}/current_user/avatar`, {
      method: "DELETE",
    });
  } catch (e) {
    throw e;
  }
}

// Get Stripe portal session link
export async function stripePortal(): Promise<APIResponse<PortalSession>> {
  try {
    const resp = await callApi(`${API_BASE}/current_user/stripe/portal`);
    return resp;
  } catch (e) {
    throw e;
  }
}
