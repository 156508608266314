export enum ModalBulkActionType {
  BulkActionBar = "bulk-action-bar",
  Resend = "resend",
  Delete = "delete",
}

export enum ModalModalType {
  MemberModal = "member-modal",
  LimitMemberModal = "limit-member-modal",
  UploadCsvModal = "upload-csv-modal",
}
