export enum WelcomeModalStep {
  noModalVisible = 0,
  detailsOrg = 1,
  invitationOrg = 2,
  successRegistration = 3,
  survey = 4,
  successIosRegistration = 5,
  mobilityJourney = 6,
}

export enum WelcomeModalType {
  OrgPayment = "org-payment",
  AthPayment = "ath-payment",
  BackToIos = "back-to-ios",
  Survey = "survey",
}
