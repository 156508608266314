import { API_BASE, APIResponse, callApi } from "./api";
import { Favorite } from "models/favorite";

export async function getFavorites(opts: {
  userId: number;
}): Promise<APIResponse<Favorite[]>> {
  try {
    const resp = await callApi(`${API_BASE}/users/${opts.userId}/favorites`);
    return resp;
  } catch (e) {
    throw e;
  }
}

export async function addFavorite(opts: {
  userId: number;
  videoId: number;
}): Promise<APIResponse<Favorite>> {
  try {
    const resp = await callApi(`${API_BASE}/users/${opts.userId}/favorites`, {
      method: "POST",
      body: opts,
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

export async function removeFavorite(opts: {
  userId: number;
  favoriteId: number;
}): Promise<void> {
  try {
    await callApi(
      `${API_BASE}/users/${opts.userId}/favorites/${opts.favoriteId}`,
      {
        method: "DELETE",
      }
    );
  } catch (e) {
    throw e;
  }
}
