import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import isBetween from "dayjs/plugin/isBetween";
import isoWeek from "dayjs/plugin/isoWeek";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import startCase from "lodash/startCase";

import { Schedule, ScheduledWorkout } from "models/schedule";
import { AppState } from "store/store";

dayjs.extend(customParseFormat);
dayjs.extend(isBetween);
dayjs.extend(isoWeek);
dayjs.extend(timezone);
dayjs.extend(utc);

export function currentDateToStr(): string {
  const today = new Date();
  return `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;
}

export const dateToString = (date) => {
  return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
};

export const tzDateToString = (curDate: Date, tz?: string) => {
  const localizedDate = dayjs(curDate ?? new Date()).tz(tz);
  return dateToString(localizedDate.toDate());
};

export const getWorkoutByDate = (
  { scheduledWorkouts }: Schedule,
  date = dayjs()
): ScheduledWorkout | null =>
  scheduledWorkouts?.find((w) => {
    const workoutDateStr = w.date?.split("T")?.[0];
    const workoutDate = dayjs(workoutDateStr, "YYYY-MM-DD");
    return date.isSame(workoutDate, "day");
  }) || null;

export function videoOfTheDayToState(
  schedules: Schedule[]
): Pick<AppState, "currentWorkout" | "currentVideo" | "schedule"> {
  if (schedules?.[0]?.scheduledWorkouts) {
    const todaySchedule = getWorkoutByDate(schedules[0]);
    return {
      currentWorkout: todaySchedule,
      currentVideo: todaySchedule?.video,
      schedule: schedules[0],
    };
  }
  return {
    currentWorkout: null,
    currentVideo: null,
    schedule: null,
  };
}

export const isDateInWeek = (
  date: dayjs.Dayjs,
  weekDate: dayjs.Dayjs
): boolean => {
  const start = weekDate.startOf("isoWeek");
  const end = weekDate.endOf("isoWeek");

  return date.isBetween(start, end, null, "[]");
};

export const wodSlugToDates = (
  scheduleSlug?: string
): { start: dayjs.Dayjs; end: dayjs.Dayjs } | null => {
  if (!scheduleSlug) return null;
  const seg = scheduleSlug.split("-");
  if (!seg || seg.length !== 5) {
    return null;
  }
  const dateFmt = "YYYY-MMMM-D";
  const startStr = `${seg[4]}-${startCase(seg[0])}-${seg[1]}`;
  const endStr = `${seg[4]}-${startCase(seg[2])}-${seg[3]}`;
  const schedStart = dayjs(startStr, dateFmt);
  const schedEnd = dayjs(endStr, dateFmt);
  if (schedStart.isValid() && schedEnd.isValid()) {
    return {
      start: schedStart,
      end: schedEnd,
    };
  }
  return null;
};

export const scheduleStartDateToSlug = (startDate: dayjs.Dayjs) => {
  const start = startDate.startOf("isoWeek");
  const end = start.endOf("isoWeek");
  const startMonth = start.format("MMMM").toLowerCase();
  const endMonth = end.format("MMMM").toLowerCase();

  return `${startMonth}-${start.date()}-${endMonth}-${end.date()}-${end.year()}`;
};

export const getWeekRange = (startDate: dayjs.Dayjs): [string, string] => {
  // Get boundaries of the selected day's week
  const start = startDate.startOf("isoWeek");
  const end = start.endOf("isoWeek");

  return [start.format("MMMM DD"), end.format("MMMM DD")];
};
