import { h, VNode, Fragment } from "preact";
import { useLayoutEffect, useState } from "preact/hooks";
import { Route, RouteProps } from "preact-router";

import User from "util/userUtil";
import { appRoute } from "util/routerUtil";
import { Toast } from "style/layout";
import Text from "components/shared/Text";
import ToastContext from "util/context/ToastContext";
import { useWindowSize } from "hooks/windowHook";
import MobileExperience from "components/shared/MobileExperience";
import { useAppProps } from "hooks/appPropHook";
import useUser from "hooks/userHook";
import UserUtil from "util/userUtil";

interface ExtendedRouteProps<Props> extends RouteProps<Props> {
  isPrivate?: boolean;
  showMobile?: boolean;
  isOnboardingStep?: boolean;
  compProps?: Props;
  category?: string;
  org?: boolean;
  slug?: string;
}

function ExtendedRoute<Props>(props: ExtendedRouteProps<Props>): VNode {
  // const path = prefixPubPath(props.path);
  const { isPrivate, showMobile, path, compProps, org } = props;
  const [toastType, setToastType] = useState("");
  const [message, setMessage] = useState("");
  const { width } = useWindowSize();
  const { user } = useUser();

  const url =
    typeof window !== "undefined" ? new URL(window.location.href) : null;

  const confirmSuccess =
    url && url.searchParams.has("confirm_success")
      ? url.searchParams.get("confirm_success")
      : "";

  const tabTeam =
    url && url.searchParams.has("tab") ? url.searchParams.get("tab") : "";

  const showTeamOrg =
    user &&
    UserUtil.isEnabledAdminOwners(user) &&
    (tabTeam === "plans-and-payments" || tabTeam === "team-details");

  // Authorize private pages only for logged users
  useLayoutEffect(() => {
    if (
      width > 0 &&
      width <= 768 &&
      !showMobile &&
      isPrivate &&
      (!User.canAccessContent() || User.canAccessContent())
    ) {
      appRoute("/", true);
    }

    if (
      org &&
      user &&
      !UserUtil.isEnabledAdminOwners(user || undefined) &&
      User.canAccessContent()
    ) {
      appRoute("/", true);
    }

    if (width > 768 && isPrivate && user && !User.canAccessContent()) {
      const isOrganization = UserUtil.isActiveMemberAdmin(user || undefined);
      appRoute(`/signup/${isOrganization ? "org" : "ath"}-payment`, true);
    }

    if (width > 768 && isPrivate && !User.isLoggedIn()) {
      appRoute(`/signin${confirmSuccess ? "?confirm_success=true" : ""}`, true);
    }
  }, [width, path]);
  const skipMobileAppNotice =
    typeof window !== "undefined" &&
    new URL(window.location.href)?.searchParams?.has("bypassmobile");
  const { mobileAppNotice } = useAppProps();

  const mobileBlocked =
    width > 0 &&
    width <= 768 &&
    showMobile &&
    mobileAppNotice &&
    !skipMobileAppNotice &&
    !showTeamOrg;

  const routeProps = props as RouteProps<Props> & Partial<Props>;

  const triggerToast = (messageToast, type) => {
    setMessage(messageToast);
    setToastType(type);
    setTimeout(() => {
      setMessage("");
    }, 1000);
  };

  return (
    <ToastContext.Provider value={{ triggerToast }}>
      <Fragment>
        <Route {...routeProps} {...compProps} />
        {mobileBlocked && (
          <MobileExperience showMobileExperience={mobileBlocked ?? false} />
        )}
        {message && (
          <Toast
            style={{
              display: "flex",
              zIndex: 10001,
            }}
            type={toastType}
          >
            <Text size="M">{message}</Text>
          </Toast>
        )}
      </Fragment>
    </ToastContext.Provider>
  );
}

export default ExtendedRoute;
