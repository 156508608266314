import { keysToCamel } from "helpers/humps";
import { AppPropKeys, AppProps } from "models/appProps";
import { API_BASE } from "./api";

export async function getAppProps(): Promise<AppProps> {
  const propKeys = Object.values(AppPropKeys);
  const params = new URLSearchParams();
  const key = propKeys.length > 1 ? "key[]" : "key";
  propKeys.forEach((k) => params.append(key, k));
  try {
    const resp = await fetch(`${API_BASE}/app_prop?${params.toString()}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
    if (!resp?.ok) {
      return Promise.resolve({});
    }
    const ap = (await resp.json()) as AppProps;
    return transformAppProps(ap);
  } catch (e) {
    console.log("errror error error", e);
    return {};
  }
}

const transformAppProps = (ap: AppProps): AppProps => {
  for (const [key, value] of Object.entries(ap)) {
    if (value === "true") {
      ap[key] = true;
    } else if (value === "false") {
      ap[key] = false;
    }
  }
  return keysToCamel(ap);
};
