// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect } from "preact/hooks";

import { appRoute } from "util/routerUtil";
import { keysToCamel, keysToSnake } from "helpers/humps";
let branchLoaded = false;
const loadBranch = () => {
  if (typeof window !== "undefined") {
    if (document.getElementById("branch")) {
      return;
    }

    (function (b, r, a, n, c, h, _, s, d, k) {
      if (!b[n] || !b[n]._q) {
        for (; s < _.length; ) c(h, _[s++]);
        d = r.createElement(a);
        d.id = "branch";
        d.async = 1;
        d.src = "https://cdn.branch.io/branch-latest.min.js";
        k = r.getElementsByTagName(a)[0];
        k.parentNode.insertBefore(d, k);
        b[n] = h;
      }
    })(
      window,
      document,
      "script",
      "branch",
      function (b, r) {
        b[r] = function () {
          /* eslint-disable prefer-rest-params */
          b._q.push([r, arguments]);
        };
      },
      { _q: [], _v: 1 },
      "addListener applyCode autoAppIndex banner closeBanner closeJourney creditHistory credits data deepview deepviewCta first getCode init link logout redeem referrals removeListener sendSMS setBranchViewData setIdentity track validateCode trackCommerceEvent logEvent disableTracking".split(
        " "
      ),
      0
    );

    branch.init(process.env.BRANCH_IO_KEY || "", (error, data) => {
      const branchData = data ? keysToCamel(data) : null;
      if (data?.referring_link) {
        localStorage.setItem("deepLink", data?.referring_link);
      }
      if (branchData?.dataParsed?.$canonicalIdentifier) {
        setTimeout(() => {
          appRoute(`/${branchData.dataParsed.$canonicalIdentifier}`, true);
        }, 20);
      }
      if (error) console.error("ERROR", error);
    });
  }
};

export const useBranch = () => {
  useEffect(() => {
    if (!branchLoaded) {
      loadBranch();
      branchLoaded = true;
    }
  }, []);

  interface LinkOptions {
    id?: string | number;
    slug?: string;
    title?: string;
    description?: string;
    thumbnailUrl?: string;
  }

  const shareDeepLink = async (
    { id, slug, title, description, thumbnailUrl }: LinkOptions,
    resource: "routine" | "path" | "pose" | "home" | "explore" | "discover"
  ): string => {
    const linkData = {
      channel: "web",
      feature: `${resource}`,
      data: {
        ...keysToSnake({
          $canonicalIdentifier: `${resource}/${slug}`,
          $ogTitle: title ?? "",
          $ogDescription: description ?? "",
          $ogImageUrl: thumbnailUrl ?? "",
          $ogUrl: `${resource}/${slug}`,
        }),
        [`${resource === "routine" ? "video" : resource}Id`]: id,
      },
    };
    return await generateDeepLink(linkData);
  };

  type LinkMetaData = {
    [k: string]: any;
  };

  const branchLinkPromise = (lnkMd: linkMetaData) =>
    new Promise((resolve, reject) => {
      branch.link(lnkMd, (err: any, link: string) => {
        if (err) {
          return reject(err);
        }
        return resolve(link);
      });
    });
  const generateDeepLink = async (lnkMd: LinkMetaData): Promise<string> =>
    await branchLinkPromise(lnkMd);

  return { shareDeepLink, generateDeepLink };
};
