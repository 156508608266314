import { API_BASE, APIResponse, callApi } from "./api";
import { Plan } from "models/subscription";

/*
A Plan can be billed monthly or yearly. Notice that the "amount_in_cents" field is a JSON hash
due to support for multiple currencies.
There are generally 4 types of Plans:
    1) Athlete Monthly
    2) Athlete Yearly
    3) Affiliate Monthly
    4) Affiliate Yearly

Note:  A User will have a Subscription and each Subscription has a Plan ID associated with it.
*/

export async function getPlans(): Promise<APIResponse<Plan[]>> {
  try {
    const resp = await callApi(`${API_BASE}/plans/stripe`);
    return resp;
  } catch (e: any) {
    return e;
  }
}
