import { useSelector, useAction } from "@preact-hooks/unistore";
import { useEffect } from "preact/hooks";

import useUser from "hooks/userHook";
import actions from "store/actions";
import { Favorite } from "models/favorite";

import {
  getFavorites,
  addFavorite as _addFavorite,
  removeFavorite as _removeFavorite,
} from "services/favorites";
import { BoundAction } from "unistore";

interface FavoritesHook {
  favorites: Favorite[] | null;
  addFavorite(videoId: number): Promise<void>;
  removeFavorite(favorite: Favorite): void;
}

let fetchingFavorites = false;

export default function useFavorites(): FavoritesHook {
  const { favorites } = useSelector("favorites");
  const setFavoritesAction = useAction(actions.setFavorites);
  const addFavoriteAction = useAction(actions.addFavorite);
  const removeFavoriteAction = useAction(actions.removeFavorite);
  const favoriteMethods = {
    addFavorite: (videoId: number) => addFavorite(addFavoriteAction, videoId),
    removeFavorite: (favorite: Favorite) =>
      removeFavorite(removeFavoriteAction, favorite),
  };
  const { errorRedirect } = useUser();

  const addFavorite = async (
    addFavoriteAction: BoundAction,
    videoId: number
  ): Promise<void> => {
    try {
      if (!user) throw new Error("No User Logged in to add favorites");
      const result = await _addFavorite({ userId: user.id, videoId });
      addFavoriteAction(result.data);
    } catch (error: any) {
      errorRedirect(error);
    }
  };

  const removeFavorite = (
    removeFavoriteAction: BoundAction,
    favorite: Favorite
  ): void => {
    try {
      if (!user) throw new Error("No User Logged in to remove favorite");
      if (!favorite || !favorite?.id) return;
      removeFavoriteAction(favorite);
      _removeFavorite({ userId: user.id, favoriteId: favorite.id });
    } catch (error: any) {
      errorRedirect(error);
    }
  };

  const { user } = useUser();
  if (!user) return { favorites: null, ...favoriteMethods };
  useEffect(() => {
    if (!favorites && !fetchingFavorites) {
      fetchingFavorites = true;
      (async () => {
        try {
          const result = await getFavorites({ userId: user.id });
          const favorites = result?.data;
          setFavoritesAction(favorites);
          fetchingFavorites = false;
        } catch (error: any) {
          errorRedirect(error);
          return null;
        }
      })();
    }
  }, []);
  return { favorites, ...favoriteMethods };
}
