import { styled } from "linaria/react";
import Colors from "./colors";
import mq from "style/breakpoints";

// TODO: Come up with better method for dynamic show hide of content to fix scrollability
/*display: ${() => {
  const skipMobileAppNotice =
    (typeof window !== "undefined" &&
    new URL(window.location.href)?.searchParams?.has("bypassmobile") ||
    window.location.pathname === "/settings";
  return skipMobileAppNotice ? "block" : "none";
}};*/
export const Layout = styled.section`
  min-height: 100%;
  overflow: hidden;
  display: block;
  ${mq.minXs} {
    display: block;
  }
  ${mq.minSm} {
    overflow: visible;
    grid-template-columns: 6rem minmax(0, 1fr);
    grid-template-rows: min-content;
  }
`;

export const Main = styled.main`
  padding: 0rem 1rem 6rem 1rem;
  position: relative;

  ${mq.minMd} {
    padding: 64px 6.5rem 0 6.5rem;
  }

  ${mq.minSm} {
    display: flex;
    flex-direction: column;
    padding: 6.5rem 2rem 0 2rem;
    overflow: hidden;
    justify-items: center;
    padding: 160px 72px 0 72px;
  }

  ${mq.sm} {
    padding: 0 64px 40px 72px;
  }
`;

export const Title = styled.h1`
  white-space: pre-wrap;
  width: fit-content;
  position: relative;
  margin-bottom: 4rem;
  ${mq.xs} {
    font-size: 2.25rem;
  }
  ${mq.minXs} {
    white-space: no-wrap;
    max-width: none;
    width: fit-content;
  }
  :after {
    content: "";
    position: absolute;
    height: 0.25rem;
    background-color: ${Colors.primary};
    content: "";
    width: 100%;
    left: 0;
    bottom: -0.5rem;
  }
`;

export const NavMenu = styled.nav`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 2.25rem 0.75rem;
  left: 0;
  top: 0;
  position: relative;
  z-index: 1000;
  flex-wrap: wrap;
  height: 7rem;

  svg {
    margin-right: 0.8rem;
    font-size: 27px;

    path {
      fill: ${Colors.bgDark0};
    }
  }

  ${mq.minXs} {
    height: initial;
    box-shadow: inset 0px -1px 0px #1a1a1a;
    display: flex;
    justify-content: center;

    > div {
      display: flex;
      width: 100%;

      > div {
        display: flex;
        align-items: center;

        > h1 {
          font-family: "Gilroy";
          color: #1a1a1a;
          font-style: normal;
          font-weight: 600;
          font-size: 40px;
          line-height: 52px;
          text-transform: inherit;
        }
      }
    }
  }
  ${mq.xs} {
    > div {
      display: flex;
      align-items: center;
      width: 100%;

      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
  ${mq.sm} {
    margin-bottom: 2rem;
    padding: 1.25rem 0.75rem;
  }
  ${mq.minSm} {
    left: auto;
    top: auto;
    flex-wrap: nowrap;
    position: absolute;
    padding: 22px 72px 22px 72px;
  }
  ${mq.minLg} {
    > div {
      max-width: 1296px;
    }
  }
`;

export const RightNavMenu = styled.div<{ expanded?: boolean }>`
  display: flex;
  flex: 1;
  justify-content: flex-start;
  flex-direction: row-reverse;
  ${mq.minSm} {
    margin-left: 9px;
    flex-direction: row;
    justify-content: ${(props) =>
      props.expanded ? "space-between" : "flex-end"};
  }
`;

export const DisplayOverlay = styled.div<{
  index?: number;
  background?: string;
}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${({ index }) => (index ? index : 3)};
  cursor: default;
  width: 100%;
  height: 100%;
  background: ${({ background }) => (background ? background : "inherit")};
`;

export const NavArrows = styled.div`
  width: 5rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  margin-right: 16px;
  > button {
    width: 2rem;
    height: 2rem;
    transition: background-color 0.25s ease-in-out;

    :hover {
      background-color: ${Colors.bgDark3};
    }

    > div {
      position: relative;
    }
  }
`;

export const Toast = styled.div<{ type: string }>`
  align-items: center;
  justify-content: center;
  padding: 16px;
  background-color: #048848;
  border-radius: 8px;
  top: 79%;
  margin: 0 auto;
  ${mq.minSm} {
    top: inherit;
    bottom: 70px;
    left: 0;
    right: 0;
    width: max-content;
    margin: 0 auto;
  }
  min-width: 12.125rem;
  min-height: 56px;
  text-align: center;
  position: fixed;
  z-index: 999;
  visibility: visible;
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.22);

  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${Colors.txtLight};
    display: flex;
    align-items: center;
  }
`;

// Settings
export const Notification = styled.div`
  padding: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  background-color: ${Colors.success};
  color: ${Colors.txtLight};
  box-shadow: 0px 16px 48px rgba(0, 0, 0, 0.22);
  border-radius: 8px;
  z-index: 2;

  ${mq.minXs} {
    margin-bottom: 2.57rem;
  }

  > span:first-child {
    margin-right: 0.5rem;
  }

  > div {
    margin-left: auto;
    cursor: pointer;
  }
`;

export const Shimmer = styled.div`
  @keyframes shimmer {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(100%);
    }
  }
  position: relative;
  overflow: hidden;
  background-color: ${Colors.bgLight3};
  border-radius: 0.25rem;
  z-index: 1;
`;

interface ShimmerBlockProps {
  width?: string;
  height?: string;
  borderRadius?: string;
  marginTop?: string;
  marginRight?: string;
  marginBottom?: string;
  marginLeft?: string;
}

export const ShimmerBlock = styled(Shimmer)<ShimmerBlockProps>`
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "100%"};
  border-radius: ${({ borderRadius }) => borderRadius || "0.25rem"};
  margin-top: ${({ marginTop }) => marginTop || "0"};
  margin-right: ${({ marginRight }) => marginRight || "0"};
  margin-bottom: ${({ marginBottom }) => marginBottom || "0"};
  margin-left: ${({ marginLeft }) => marginLeft || "0"};
`;

export const FadeIn = `
  @keyframes fadeIn {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 1;
    }
  }

  animation: fadeIn 300ms ease-in backwards
`;
