import { Subscription } from "models/subscription";
import { API_BASE, APIResponse, callApi } from "./api";
import {
  SubscriptionGroup,
  SubscriptionGroupAnalytics,
  SubscriptionGroupInvite,
} from "models/subscriptionGroup";

interface UpsertInviteResponse {
  message: string;
  members: {
    data: SubscriptionGroupInvite[];
  };
}
export interface ValidateGroupInviteCodeResponse {
  valid: boolean;
  inviteEmail: string | null;
  organization: SubscriptionGroup | null;
}

export async function getSubscriptionGroup(): Promise<
  APIResponse<SubscriptionGroup>
> {
  try {
    const resp = await callApi(`${API_BASE}/subscription_groups`);
    return resp;
  } catch (e: any) {
    return e;
  }
}
export async function getSubscriptionGroupAdmin(): Promise<
  APIResponse<Subscription>
> {
  try {
    const resp = await callApi(`${API_BASE}/subscription_groups/subscription`);
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function getSubscriptionGroupAnalytics(): Promise<
  APIResponse<SubscriptionGroupAnalytics>
> {
  try {
    const resp = await callApi(`${API_BASE}/subscription_groups/analytics`);
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function updateSubscriptionGroup(opts: {
  name: string;
  industry: string;
  companySize: string;
}): Promise<APIResponse<SubscriptionGroup>> {
  try {
    const resp = await callApi(`${API_BASE}/subscription_groups/update`, {
      method: "PUT",
      body: opts,
    });
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function getSubscriptionGroupInvites(): Promise<
  APIResponse<{
    members: {
      data: SubscriptionGroupInvite[];
    };
  }>
> {
  try {
    const resp = await callApi(`${API_BASE}/subscription_groups/members`);
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function getMemberListLazyLoading(opts: {
  perPage: number;
  page: number;
}): Promise<any> {
  try {
    const resp = await callApi(
      `${API_BASE}/subscription_groups/members?per_page=${opts.perPage}&page=${opts.page}`
    );
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function createSubscriptionGroupInvite(opts: {
  email: string[];
}): Promise<APIResponse<UpsertInviteResponse>> {
  try {
    const resp = await callApi(
      `${API_BASE}/subscription_groups/create-invite`,
      {
        method: "POST",
        body: opts,
      }
    );
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function deleteSubscriptionGroupInvite(opts: {
  inviteIds: number[];
}): Promise<APIResponse<UpsertInviteResponse>> {
  try {
    const resp = await callApi(
      `${API_BASE}/subscription_groups/delete-members`,
      {
        method: "DELETE",
        body: opts,
      }
    );
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function resendSubscriptionGroupInvite(opts: {
  inviteIds: number[];
}): Promise<APIResponse<UpsertInviteResponse>> {
  try {
    const resp = await callApi(
      `${API_BASE}/subscription_groups/resend-invites`,
      {
        method: "POST",
        body: opts,
      }
    );
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function validateGroupInviteCode(
  inviteCode: string
): Promise<APIResponse<any>> {
  try {
    const resp = await callApi(
      `${API_BASE}/subscription_groups/validate?invite_code=${inviteCode}`
    );
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function manageAdminSubscriptionGroups(opts: {
  memberId: string;
  actionType: string;
}): Promise<APIResponse<any>> {
  try {
    const resp = await callApi(
      `${API_BASE}/subscription_groups/manage-admin/${opts.memberId}`,
      {
        method: "POST",
        body: { actionType: opts.actionType },
      }
    );
    return resp;
  } catch (e: any) {
    return e;
  }
}
