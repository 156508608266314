import { itemsPerPage, pubPathNoTrailingSlash } from "config";
import { keysToSnake, toCamel } from "helpers/humps";
import { SearchVideoOptions, TSort } from "models/search";

export function searchOptsToQueryString(opts?: SearchVideoOptions): string {
  const params = new URLSearchParams();
  const snakeOpts = keysToSnake(opts);
  for (const param of Object.keys(snakeOpts)) {
    const value = snakeOpts[param];
    const isArr = Array.isArray(value);
    const key =
      isArr && !param?.includes("_sort") && !param?.includes("_filters")
        ? `${param}_filters[]`
        : param;
    if (isArr && value.filter((_) => _).length) {
      value.forEach((v) => params.append(key, v));
    } else if (!isArr) {
      const val = value?.toString();
      params.set(key, val);
    }
  }
  if (opts?.newestSort) params.set("newest_sort", opts?.newestSort);
  if (opts?.difficultySort) params.set("difficulty_sort", opts.difficultySort);
  if (opts?.durationSort) params.set("duration_sort", opts.durationSort);
  const query =
    opts && Object.keys(opts).length > 0 ? decodeURI(params.toString()) : "";
  return query;
}

export function paramstoSearchOpts(
  currentUrl: string | null
): SearchVideoOptions {
  const opts: SearchVideoOptions = {};
  try {
    if (!currentUrl) {
      return {};
    }
    const url = new URL(
      currentUrl && currentUrl?.includes("http")
        ? currentUrl
        : `http://localhost/${currentUrl}`
    );
    for (const [key, value] of url.searchParams) {
      if (key?.includes("_sort")) {
        const k = toCamel(key);
        opts[k] = value;
      } else if (key?.includes("_filter")) {
        const filterKey = key.split("_filter")[0];
        const k = toCamel(filterKey);
        if (opts[k]) {
          opts[k].push(value);
        } else {
          opts[k] = [value];
        }
      } else if (key === "q") {
        opts[toCamel(key)] = value;
      } else if (key === "type") {
        opts[key] = value;
      }
    }
    return opts;
  } catch (err) {
    return {};
  }
}

export function shouldSearch(opts: SearchVideoOptions): boolean {
  // Should submit query when there is a q param or other filters besides default are set
  const isRecentlyAdded =
    typeof window !== "undefined" &&
    [
      `${pubPathNoTrailingSlash}/routines/newly-added`,
      `${pubPathNoTrailingSlash}/routines`,
      `${pubPathNoTrailingSlash}/search`,
    ].includes(window.location.pathname);
  return (
    opts.hasOwnProperty("q") ||
    Object.keys(opts).length > Object.keys(defaultSearchOpts).length ||
    isRecentlyAdded
  );
}

const defaultSearchOpts: Record<string, number | TSort> = {
  from: 0,
  size: itemsPerPage,
};

export function addDefaultSearchOpts(
  opts: SearchVideoOptions
): SearchVideoOptions {
  const defaultOps = defaultSearchOpts;

  if (opts?.type) {
    delete opts.type;
  }

  if (opts?.relevancySort || opts?.durationSort) {
    delete defaultOps.newestSort;
  } else if (opts?.newestSort && !opts?.relevancySort) {
    defaultOps.newestSort = "desc";
    delete defaultOps.relevancySort;
  } else if (!opts?.relevancySort) {
    delete defaultOps.newestSort;
    defaultOps.relevancySort = "desc";
  }
  return { ...defaultOps, ...opts };
}

const defaultSearchOptsMember = {
  from: 0,
  size: itemsPerPage,
  newestSort: true,
};

export function addDefaultSearchOptsMember(opts: any): any {
  return { ...defaultSearchOptsMember, ...opts };
}
