import { FunctionalComponent } from "preact";
import { useEffect } from "preact/hooks";
import { appRoute } from "util/routerUtil";

interface RedirectProps {
  to: string;
  carryQuery?: boolean;
}

const Redirect: FunctionalComponent<RedirectProps> = ({ to, carryQuery }) => {
  useEffect(() => {
    if (typeof window !== undefined && carryQuery) {
      appRoute(`${to}${window.location.search}`, true);
    } else {
      appRoute(to, true);
    }
  }, []);

  return null;
};

export default Redirect;
