import { Coupon } from "models/coupon";
import { API_BASE, APIResponse, callApi } from "./api";

export interface ValidateCouponResponse {
  valid: boolean | null;
  coupon: Coupon | null;
}

export async function validateCoupon(
  couponCode: string
): Promise<APIResponse<ValidateCouponResponse>> {
  try {
    const resp = await callApi(
      `${API_BASE}/coupons/validate?coupon_code=${couponCode}`
    );
    return resp;
  } catch (e) {
    throw e;
  }
}
