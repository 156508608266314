import { useCurrentUrl } from "hooks/currentUrlHook";
import { h, FunctionalComponent } from "preact";
import Helmet from "react-helmet";
import User from "util/userUtil";

const Head: FunctionalComponent = (props) => {
  const meta = User.isLoggedIn()
    ? [
        {
          name: "apple-itunes-app",
          content: "app-id=1175346453, app-argument=romwod://",
        },
      ]
    : [];

  const { currentUrl } = useCurrentUrl() ?? "";
  const curUrl = currentUrl ?? "";
  const headTags = props?.children as any;
  const metaTitle = Array.isArray(headTags)
    ? headTags?.find((p) => p.type === "title")?.props?.children ?? ""
    : headTags.type === "title"
    ? headTags.props?.children
    : "";
  const metaDescription = Array.isArray(headTags)
    ? headTags?.find((p) => p.props?.property === "og:description")?.props
        ?.content ?? ""
    : headTags?.props?.property === "og:description"
    ? headTags?.props?.content
    : "";
  const metaImage = Array.isArray(headTags)
    ? headTags?.find((p) => p.props?.property === "og:image")?.props?.content ??
      ""
    : headTags.property === "og:image"
    ? headTags.props?.content
    : "";
  return (
    <Helmet
      defer={false}
      defaultTitle="pliability"
      titleTemplate="%s // pliability"
      meta={meta}
    >
      {props.children}
      {metaDescription && <meta name="description" content={metaDescription} />}
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:url" content={curUrl} />
      <meta property="og:site_name" content="pliability" />
      <meta
        property="article:publisher"
        content="https://www.facebook.com/romwod"
      />
      <meta property="fb:admins" content="1040700033" />
      <meta property="fb:app_id" content="291035778077587" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:site" content="@pliability" />
      <meta name="twitter:creator" content="@pliability" />
      {metaDescription && (
        <meta name="twitter:description" content={metaDescription} />
      )}
      {metaImage && <meta name="twitter:image" content={metaImage} />}
    </Helmet>
  );
};

export default Head;
