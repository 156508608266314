import { h } from "preact";

export const RenderIcon = (icon: string) => {
  switch (icon) {
    case "info-icon":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z"
            stroke="white"
            strokeWidth="1.25"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 13.3333V10"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M10 6.66663H10.0083"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "share-icon":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.31183 6.25H8.125V7.5H5.31285C4.89852 7.50053 4.50131 7.66535 4.20833 7.95833C3.91535 8.25131 3.75053 8.64852 3.75 9.06285V16.5622C3.75053 16.9765 3.91535 17.3737 4.20833 17.6667C4.50139 17.9597 4.89873 18.1246 5.31317 18.125H14.6868C15.1013 18.1246 15.4986 17.9597 15.7917 17.6667C16.0847 17.3736 16.2496 16.9763 16.25 16.5618V9.06317C16.2496 8.64873 16.0847 8.25139 15.7917 7.95833C15.4987 7.66535 15.1015 7.50053 14.6871 7.5H11.875V6.25H14.6882C15.4337 6.2508 16.1484 6.5473 16.6756 7.07445C17.2027 7.60159 17.4992 8.31633 17.5 9.06183V16.5632C17.4992 17.3087 17.2027 18.0234 16.6756 18.5506C16.1484 19.0777 15.4337 19.3742 14.6882 19.375H5.31183C4.56633 19.3742 3.85159 19.0777 3.32445 18.5506C2.7973 18.0234 2.5008 17.3087 2.5 16.5632V9.06183C2.5008 8.31633 2.7973 7.6016 3.32445 7.07445C3.8516 6.5473 4.56633 6.2508 5.31183 6.25Z"
            fill="white"
          />
          <path
            d="M10.625 2.75869L12.6832 4.81651C12.8014 4.92877 12.9587 4.99043 13.1217 4.98834C13.2847 4.98626 13.4404 4.92059 13.5556 4.80534C13.6709 4.69009 13.7365 4.53437 13.7386 4.3714C13.7407 4.20842 13.6791 4.05108 13.5668 3.93291L10.4418 0.807913C10.3246 0.690792 10.1657 0.625 10 0.625C9.83431 0.625 9.6754 0.690792 9.5582 0.807913L6.43321 3.93291C6.32094 4.05108 6.25928 4.20842 6.26137 4.3714C6.26345 4.53437 6.32912 4.69009 6.44438 4.80534C6.55963 4.92059 6.71534 4.98626 6.87831 4.98834C7.04129 4.99043 7.19863 4.92877 7.3168 4.81651L9.375 2.75869V13.125C9.375 13.4702 9.65482 13.75 10 13.75C10.3452 13.75 10.625 13.4702 10.625 13.125V2.75869Z"
            fill="white"
          />
        </svg>
      );

    case "skip-icon":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M5.36416 3.22409C4.54571 2.56933 3.33329 3.15204 3.33329 4.20018V15.7997C3.33329 16.8478 4.54571 17.4305 5.36416 16.7757L12.6138 10.976C13.2393 10.4756 13.2393 9.52424 12.6138 9.02383L5.36416 3.22409Z"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M15.8334 4.16699V15.8337"
            stroke="white"
            strokeWidth="1.66667"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      );
    case "end-icon":
      return (
        <svg
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1.875 5.00008C1.875 4.6549 2.15482 4.37508 2.5 4.37508H17.5C17.8452 4.37508 18.125 4.6549 18.125 5.00008C18.125 5.34526 17.8452 5.62508 17.5 5.62508H2.5C2.15482 5.62508 1.875 5.34526 1.875 5.00008Z"
            fill="#FF453A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.33333 2.29175C8.05707 2.29175 7.79211 2.40149 7.59676 2.59685C7.40141 2.7922 7.29167 3.05715 7.29167 3.33341V5.00008C7.29167 5.34526 7.01184 5.62508 6.66667 5.62508C6.32149 5.62508 6.04167 5.34526 6.04167 5.00008V3.33341C6.04167 2.72563 6.28311 2.14273 6.71288 1.71296C7.14265 1.28319 7.72555 1.04175 8.33333 1.04175H11.6667C12.2745 1.04175 12.8573 1.28319 13.2871 1.71296C13.7169 2.14273 13.9583 2.72563 13.9583 3.33341V5.00008C13.9583 5.34526 13.6785 5.62508 13.3333 5.62508C12.9882 5.62508 12.7083 5.34526 12.7083 5.00008V3.33341C12.7083 3.05715 12.5986 2.7922 12.4032 2.59685C12.2079 2.40149 11.9429 2.29175 11.6667 2.29175H8.33333ZM4.16667 4.37508C4.51184 4.37508 4.79167 4.6549 4.79167 5.00008V16.6667C4.79167 16.943 4.90141 17.208 5.09676 17.4033C5.29211 17.5987 5.55707 17.7084 5.83333 17.7084H14.1667C14.4429 17.7084 14.7079 17.5987 14.9032 17.4033C15.0986 17.208 15.2083 16.943 15.2083 16.6667V5.00008C15.2083 4.6549 15.4882 4.37508 15.8333 4.37508C16.1785 4.37508 16.4583 4.6549 16.4583 5.00008V16.6667C16.4583 17.2745 16.2169 17.8574 15.7871 18.2872C15.3573 18.717 14.7745 18.9584 14.1667 18.9584H5.83333C5.22555 18.9584 4.64265 18.717 4.21288 18.2872C3.78311 17.8574 3.54167 17.2745 3.54167 16.6667V5.00008C3.54167 4.6549 3.82149 4.37508 4.16667 4.37508Z"
            fill="#FF453A"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 8.54175C10.3452 8.54175 10.625 8.82157 10.625 9.16675V14.1667C10.625 14.5119 10.3452 14.7917 10 14.7917C9.65482 14.7917 9.375 14.5119 9.375 14.1667V9.16675C9.375 8.82157 9.65482 8.54175 10 8.54175Z"
            fill="#FF453A"
          />
        </svg>
      );

    default:
      return <div />;
  }
};
