import { API_BASE, APIResponse, callApi } from "./api";
import {
  TargetArea,
  Duration,
  Equipment,
  Exercise,
  Pose,
  Athlete,
  Sport,
} from "models/videoDetail";
import { Video } from "models/video";

export async function getVideoDetails(): Promise<
  APIResponse<{
    targetAreas: TargetArea[];
    durations: Duration[];
    equipment: Equipment[];
    exercises: Exercise[];
    poses: Pose[];
    athletes: Athlete[];
  }>
> {
  try {
    const resp = await callApi(`${API_BASE}/video_details`);
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function getTargetAreas(): Promise<APIResponse<TargetArea[]>> {
  try {
    const resp = await callApi(`${API_BASE}/target_areas`);
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function getAthletes(): Promise<APIResponse<Athlete>> {
  try {
    const resp = await callApi(`${API_BASE}/athletes`);
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function getSports(): Promise<APIResponse<Sport>> {
  try {
    const resp = await callApi(`${API_BASE}/sports`);
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function getPoses(): Promise<APIResponse<Pose>> {
  try {
    const resp = await callApi(`${API_BASE}/poses`);
    return resp;
  } catch (e: any) {
    return e;
  }
}

export async function getPoseFilter(
  pose: string
): Promise<APIResponse<Video[]>> {
  try {
    const resp = await callApi(
      `${API_BASE}/videos/search?pose_filters[]=${pose}`
    );
    return resp;
  } catch (e: any) {
    return e;
  }
}
