import { RefPartner } from "models/refPartner";
import { API_BASE, APIResponse, callApi } from "./api";

export async function getRefPartners(
  slug?: string | null
): Promise<APIResponse<RefPartner> | { data: null }> {
  try {
    const resp = await callApi(
      slug
        ? `${API_BASE}/ref_partners/by-slug/${slug}`
        : `${API_BASE}/ref_partners`
    );
    return resp;
  } catch {
    // swallow refferal 404's
    return {
      data: null,
    };
  }
}
