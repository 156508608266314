import { h } from "preact";
import { useMemo } from "preact/hooks";
import { styled } from "linaria/react";
import {
  textXL,
  textBoldL,
  textRegularL,
  textBoldM,
  textRegularM,
  textBoldS,
  textRegularS,
  textBoldXS,
  textRegularXS,
  textActionL,
  textActionM,
  textActionS,
} from "components/AppStyle";

interface ITextProps {
  children: JSX.Element | JSX.Element[] | string | number;
  size: "XL" | "L" | "M" | "S" | "XS";
  isBold?: boolean;
  isAction?: boolean;
  color?: string;
  className?: string;
}

const Text = (
  { children, size, isBold, isAction, color, ...props }: ITextProps = {
    children: "",
    size: "M",
    isBold: false,
    isAction: false,
    color: "",
  }
): JSX.Element | null => {
  const StyledSpan = useMemo((): ReturnType<typeof styled.span> | null => {
    if (isAction) {
      switch (size) {
        case "L":
        case "XL":
          return styled.span`
            ${textActionL}
            color: ${(props) => props.color || ""};
          `;
        case "M":
          return styled.span`
            ${textActionM}
            color: ${(props) => props.color || ""};
          `;
        case "S":
        case "XS":
          return styled.span`
            ${textActionS}
            color: ${(props) => props.color || ""};
          `;
      }
    } else {
      if (isBold) {
        switch (size) {
          case "XL":
            return styled.span`
              ${textXL}
            `;
          case "L":
            return styled.span`
              ${textBoldL}
            `;
          case "M":
            return styled.span`
              ${textBoldM}
            `;
          case "S":
            return styled.span`
              ${textBoldS}
            `;
          case "XS":
            return styled.span`
              ${textBoldXS}
            `;
        }
      } else {
        switch (size) {
          case "L":
            return styled.span`
              ${textRegularL}
            `;
          case "M":
            return styled.span`
              ${textRegularM}
            `;
          case "S":
            return styled.span`
              ${textRegularS}
            `;
          case "XS":
            return styled.span`
              ${textRegularXS}
            `;
        }
      }
    }

    // No option fits
    return null;
  }, [children, size, isBold, isAction, color]);

  return StyledSpan && <StyledSpan {...props}>{children}</StyledSpan>;
};

export default Text;
