const colors = {
  // Icon colors
  primaryYellow: "#FFFF00",
  white: "white",
  yellow: "#FFB300",

  // Brand colors
  primaryDark: "#E69500",
  primary: "#FFB300",
  primaryLight: "#FFC845",
  secondaryDark: "#00A6D9",
  secondary: "#01B6ED",
  secondaryLight: "#40D2FF",
  // Background colors
  bgDark0: "#000000",
  bgDark1: "#151515",
  bgDark2: "#202020",
  bgDark3: "#2E2E2E",
  bgDark4: "#1A1A1A",
  bgDark5: "#545454",
  bgLight0: "#BFBFBF",
  bgLight1: "#EEEEEE",
  bgLight2: "#FFFFFF",
  bgLight3: "#F6F6F6",
  bgLight4: "#e2e2e2",
  bgGrey0: "#8E8E93",
  bgGrey1: "#333333",
  bgGrey2: "#D1D1D6",
  bgBlue0: "#0d41e1",
  // Text colors
  txtDark: "#000000",
  txtDark1: "#1A1A1A",
  txtGrey0: "#404040",
  txtGrey1: "#808080",
  txtGrey2: "#BFBFBF",
  txtGrey3: "#8E8E93",
  txtGrey4: "#545454",
  txtGrey5: "#A2A2A2",
  txtGrey6: "#333333",
  txtGrey7: "#606060",
  txtGrey8: "#E3E3E3",
  txtGrey9: "#D1D1D6",
  txtLight: "#FFFFFF",
  txtLight2: "#F6F6F6",

  // Status colors - Inputs
  error: "#E11900",

  // Notification
  success: "#048848",
  warning: "#FEBE10",

  // OLD
  inactive: "#999",
  pliabilityBlack: "#000000",
  pliabilityGrey01: "#000000",
  pliabilityGrey02: "#202020",
  pliabilityGrey03: "#303030",
  pliabilityGrey04: "#606060",
  pliabilityWhite: "#ffffff",
  pliabilityWhite72: "rgba(255,255,255,.72)",
  pliabilityYellow: "#ffb300",
  pliabilityBlue: "#01b6ed",
  pliabilityDarkBlue: "#103abe",
  pliabilityGreen: "#01b6ed",
  pliabilityRed: "#f15c5c",
};

export default colors;
