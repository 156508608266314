import { keysToSnake } from "helpers/humps";
import { muxDataKey } from "config";
import { Video } from "models/video";
import { TutorialVideo } from "models/videoDetail";

const isOS = () => navigator.userAgent.match(/ipad|iphone/i);

let textArea: HTMLTextAreaElement;

const createTextArea = (text) => {
  textArea = document.createElement("textarea") as HTMLTextAreaElement;
  textArea.style.opacity = "0";
  textArea.value = text;
  document.body.appendChild(textArea);
};

const selectText = () => {
  if (isOS()) {
    const range = document.createRange();
    range.selectNodeContents(textArea);
    const selection = window.getSelection();
    selection?.removeAllRanges();
    selection?.addRange(range);
    textArea.setSelectionRange(0, 999999);
  } else textArea.select();
};

const copy = async () => {
  if (typeof window !== "undefined") {
    try {
      await navigator.clipboard.writeText(`${textArea.value}`);
    } catch (error) {
      console.error("ERROR", error);
    }
    document.body.removeChild(textArea);
  }
};

export const copyToClipboard = (text: string): void => {
  createTextArea(text);
  selectText();
  copy();
};

export const scriptAlreadyExists = (id: string) => {
  if (typeof window !== "undefined") {
    return document.getElementById(id) !== null;
  }
  return false;
};

export const isCasting = () =>
  Boolean(
    window?.cast?.framework?.CastContext?.getInstance()?.R == "CONNECTED"
  );

export const muxData = (video: Video | TutorialVideo, userId?: number) => {
  const { mediaId, title, durationInSeconds } = video;
  const playerInitTime = Date.now();
  return {
    debug: false,
    data: keysToSnake({
      envKey: muxDataKey,
      playerName: "Mux Web Player",
      viewerUserId: userId,
      playerInitTime: playerInitTime,
      videoId: mediaId,
      videoTitle: title,
      videoDuration: durationInSeconds * 1000,
      videoStreamType: "on-demand",
      videoCdn: "mux",
    }),
  };
};
