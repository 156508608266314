import { API_BASE, APIResponse, callApi } from "./api";
import { WatchHistory, ShareActivity } from "models/watchHistory";

import { Dayjs } from "dayjs";

export async function upsertVideoWatch(opts: {
  id?: number;
  videoId: number;
  percent: number;
  seconds: number;
  completed: boolean;
}): Promise<APIResponse<void>> {
  try {
    const resp = await callApi(`${API_BASE}/activity/watch`, {
      method: "POST",
      body: opts,
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

export async function deleteWatchEntry(id: number): Promise<APIResponse<void>> {
  try {
    const resp = await callApi(`${API_BASE}/activity/watch`, {
      method: "DELETE",
      body: { id },
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

export async function getUserShareActivity(): Promise<
  APIResponse<ShareActivity>
> {
  try {
    const resp = await callApi(`${API_BASE}/activity/share`);
    return resp;
  } catch (e) {
    throw e;
  }
}

export async function getUserWatchHistory(
  startDate: Dayjs,
  endDate: Dayjs
): Promise<WatchHistory> {
  try {
    const params = new URLSearchParams();
    const dateFmt = "YYYY-MM-DD";
    params.set("start_date", startDate.format(dateFmt));
    params.set("end_date", endDate.format(dateFmt));
    const resp = await callApi(
      `${API_BASE}/activity/history?${params.toString()}`
    );
    const { watchHistory, timeByDay } = resp?.data;
    return { watchHistory, timeByDay };
  } catch (e) {
    throw e;
  }
}
