import { FunctionalComponent, h, Fragment } from "preact";
import { styled } from "linaria/react";
import Button from "components/shared/Button";
import Colors from "style/colors";
import mq from "style/breakpoints";
import Input from "components/shared/Input";
import Icon, { IconTypes } from "components/shared/Icon";
import { RenderIcon } from "components/layout/RenderIcon";
import { Close, ContactOurTeam, Container, Divider, Warning } from "style/team";

const InviteMembersContent = styled.div<{ showWarning: boolean }>`
  padding: 0;
  width: 100%;
  padding: 40px;

  h1 {
    color: #000000;
    font-family: "Neusa Next Pro";
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 4px;
  }

  > p {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    color: #000000;
    margin-bottom: ${({ showWarning }) => (showWarning ? "24px" : "40px")};

    span {
      font-size: 18px;
      font-weight: 700;
      line-height: 28px;
      color: #0d41e1;
    }
  }

  > div > button {
    width: 100%;
    height: 56px;
    background: ${Colors.bgDark0};
    border-radius: 9999px;
    border: none;
    cursor: pointer;
    display: inline-flex;

    > div > span {
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    :disabled,
    &.inactive {
      color: ${Colors.bgLight2} !important;
      background-color: ${Colors.bgGrey2} !important;
    }

    :hover {
      opacity: 0.9;
    }
  }
`;

const AddEmailContainer = styled.div<{
  hasEmail: boolean;
  showContactOurTeam: boolean;
}>`
  position: relative;
  > form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 14px;
    margin-bottom: 40px;

    .input-email {
      width: 100%;

      input {
        color: #000000;
        font-size: 18px;
        font-weight: 500;
        line-height: 28px;
        padding: 14px 16px;
        padding-right: 70px;
        cursor: ${({ showContactOurTeam }) =>
          showContactOurTeam ? "no-drop" : "inherit"};
      }

      .error {
        position: absolute;
        top: 56px;
        color: #e11900;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
      }

      label {
        display: none !important;
      }
    }

    > button {
      width: 32px;
      height: 32px;
      background: ${({ hasEmail }) =>
        hasEmail ? Colors.bgDark0 : Colors.bgLight1};
      border-radius: 9999px;
      border: none;
      position: absolute;
      right: 16px;
      cursor: ${({ hasEmail }) => (hasEmail ? "pointer" : "no-drop")};
      outline: none;
      color: ${({ hasEmail }) =>
        hasEmail ? Colors.txtLight : Colors.txtGrey3};
      font-size: 20px;
      font-weight: 500;
    }
  }
`;

const MembersInvite = styled.div<{ showWarning?: boolean }>`
  border-radius: 8px;
  border: 1px solid #8e8e93;
  padding: 16px;
  margin-bottom: 40px;
  max-height: ${({ showWarning }) => (showWarning ? "30vh" : "45vh")};
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;

  @-moz-document url-prefix() {
    scrollbar-width: none;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #333333;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      opacity: 0.34;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > button {
      background: transparent;
      padding: 0;

      :hover:active {
        background: transparent;
      }
    }
  }
`;

const RemoveInviteButton = styled.button`
  width: 32px;
  height: 32px;
  background: ${Colors.bgLight1} !important;
  border-radius: 9999px;
  border: none;
  position: absolute;
  right: 32px;
  cursor: pointer;
  outline: none;
  color: #e11900;
  font-size: 20px;
  font-weight: 500;
`;

interface InviteManuallyModalProps {
  onClose?: () => void;
  setEmailListUpload: (data: any) => void;
  setEmailList: (data: any) => void;
  showWarning: boolean;
  extraSeats: number;
  showContactOurTeam: boolean;
  email: string;
  addEmail: (e: h.JSX.TargetedEvent<HTMLFormElement, Event>) => void;
  emailError: string;
  setEmail: (data: any) => void;
  setError: (data: any) => void;
  setEmailError: (data: any) => void;
  emailList: string[];
  totalMembers: number;
  billingCount: number;
  loading: boolean;
  sendInvite: (data: string) => void;
}

const InviteManuallyModal: FunctionalComponent<InviteManuallyModalProps> = ({
  onClose,
  setEmailListUpload,
  setEmailList,
  showWarning,
  extraSeats,
  showContactOurTeam,
  email,
  addEmail,
  emailError,
  setEmail,
  setError,
  setEmailError,
  emailList,
  totalMembers,
  billingCount,
  loading,
  sendInvite,
}: InviteManuallyModalProps) => {
  const { X } = IconTypes;

  return (
    <Container>
      <Close
        onClick={() => {
          onClose?.();
          setEmailListUpload([]);
          setEmailList([]);
        }}
      >
        <Icon name={X} />
      </Close>
      <InviteMembersContent showWarning={showWarning}>
        <h1>Invite</h1>
        <p>
          <span>{extraSeats >= 0 ? extraSeats : 0} seats</span> remaining before
          additional billing cost.
        </p>
        {showContactOurTeam ? (
          <ContactOurTeam>
            {RenderIcon("warning-red")}
            <h3>
              If you want to add more members you are going to need a custom
              plan. Our team is happy to help you out.{" "}
              <span
                onClick={() => {
                  if (typeof window !== "undefined" && window.Intercom) {
                    window.Intercom("show");
                  }
                }}
              >
                Contact Our Team{" "}
              </span>
            </h3>
          </ContactOurTeam>
        ) : showWarning ? (
          <Warning showContactOurTeam={showContactOurTeam}>
            {RenderIcon("warning")}
            <h3>
              Current prepaid member plan has reached its maximum capacity.
              Adding any additional members will incur an extra fee.
            </h3>
          </Warning>
        ) : null}
        <AddEmailContainer
          hasEmail={!!email?.length && !showContactOurTeam}
          showContactOurTeam={showContactOurTeam}
        >
          <form onSubmit={addEmail}>
            <Input
              id="email"
              label="Email"
              type="text"
              value={email}
              error={emailError}
              autocomplete="off"
              onChange={(e) => {
                setEmail((e?.target as HTMLInputElement)?.value);
                setError(false);
                setEmailError("");
              }}
              placeholder="Enter Email"
              className="input-email"
              disabled={showContactOurTeam}
            />
            <button type="submit">+</button>
          </form>
        </AddEmailContainer>
        {!!emailList?.length && (
          <MembersInvite showWarning={showWarning}>
            {emailList?.map((email, index) => (
              <Fragment key={index}>
                <div>
                  <span>{email}</span>
                  <RemoveInviteButton
                    onClick={() => {
                      const filterEmail = emailList?.filter(
                        (em) => em !== email
                      );
                      setEmailList(filterEmail);
                    }}
                  >
                    -
                  </RemoveInviteButton>
                </div>
                {emailList?.length > 0 && index !== emailList?.length - 1 && (
                  <Divider />
                )}
              </Fragment>
            ))}
          </MembersInvite>
        )}
        <div style={{ width: "100%" }}>
          <Button
            size="S"
            text={`${
              totalMembers > billingCount
                ? "Continue"
                : `Invite ${!!emailList?.length ? emailList?.length : ""} ${
                    emailList?.length > 1 ? "members" : "member"
                  }`
            }`}
            handleClick={() => {
              sendInvite(totalMembers > billingCount ? "billing-update" : "");
            }}
            loading={loading}
            disabled={!emailList?.length || showContactOurTeam}
            unwrapped={true}
          />
        </div>
      </InviteMembersContent>
    </Container>
  );
};

export default InviteManuallyModal;
