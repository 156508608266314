import { signout } from "services";
import { User } from "models/user";
import { TOKEN_NAME } from "services/api";
import { WATCH_HISTORY_KEY } from "hooks/watchHistoryHook";
import { datadogRum } from "@datadog/browser-rum";

const USER_DATA_KEY = "userData";
const USER_ORG_SIGNUP_KEY = "signupOrganization";
const USER_PROMO_KEY = "userPromo";
const USER_REFERRAL_KEY = "userReferralCode";
const USER_CAMPAIGN_KEY = "userCampaign";
const USER_UTM_CAMPAIGN_KEY = "userUtmCampaign";
const USER_UTM_MEDIUM_KEY = "userUtmMedium";
const USER_UTM_SOURCE_KEY = "userUtmSource";
const USER_UTM_ENTRY_KEY = "userUtmEntry";
const SUB_GROUP_MEMBER = "sub_group_member";
const SUB_GROUP_OWNER = "sub_group_owner";
const SUB_GROUP_ACTIVE = "sub_group_active";
const SUB_GROUP_ADMIN = "sub_group_admin";
export const SUBSCRIBE_ERRORS = "subscription_creation_errors";
export const USER_G_AUTH_KEY = "gAuth";

class userUtilSingleton {
  static save(data?: User | string | null): void {
    if (typeof window !== "undefined") {
      try {
        if (typeof data === "string") {
          localStorage.setItem(USER_DATA_KEY, data);
          data = JSON.parse(data);
        } else {
          localStorage.setItem(USER_DATA_KEY, JSON.stringify(data));
        }
      } catch (err) {
        console.error(err);
      }
    }
  }

  static isLoggedIn(): boolean {
    if (this.hasAccessToken() && this.hasUserData()) {
      return true;
    }
    return false;
  }

  static hasAccessToken(): boolean {
    //API v2 token
    if (typeof window !== "undefined" && localStorage.getItem(TOKEN_NAME)) {
      return true;
    }
    return false;
  }

  static hasUserData(): boolean {
    const userData = this.userData();
    if (!userData) {
      return false;
    }
    return true;
  }

  static userData(): User | null {
    if (typeof window !== "undefined") {
      const data = localStorage.getItem(USER_DATA_KEY);
      if (!data) {
        return null;
      }
      try {
        const d = JSON.parse(data);
        return d?.data ?? d;
      } catch {
        return null;
      }
    }
    return null;
  }

  // User data will need a refresh if there are auth tokens
  // but no userData cookie
  // will also be refreshed if there are content_blockers
  static needsRefresh(): boolean {
    if (this.hasAccessToken() && !this.hasUserData()) {
      return true;
    }
    if (this.hasUserData()) {
      const data = localStorage.getItem(USER_DATA_KEY);
      if (typeof data === "string") {
        try {
          const { data: user } = JSON.parse(data);
          if (user?.contentBlockers) {
            return true;
          }
        } catch {
          return true;
        }
      }
    }
    return false;
  }

  static setSignUpPromo(promo: boolean, campaign: string): void {
    if (typeof window !== "undefined") {
      localStorage.setItem(USER_PROMO_KEY, promo ? "true" : "false");
      localStorage.setItem(USER_CAMPAIGN_KEY, campaign);
    }
  }

  static getSignUpPromo(): { promo: boolean; campaign: string } | null {
    if (typeof window !== "undefined") {
      const promoValue = localStorage.getItem(USER_PROMO_KEY);
      const campaignValue = localStorage.getItem(USER_CAMPAIGN_KEY);
      return {
        promo: promoValue === "true" ? true : false,
        campaign: campaignValue ? campaignValue : "",
      };
    }
    return null;
  }

  static unsetSignUpPromo(): void {
    if (typeof window !== "undefined") {
      localStorage.removeItem(USER_PROMO_KEY);
      localStorage.removeItem(USER_CAMPAIGN_KEY);
    }
  }

  static setUtm(
    campaign: string,
    source: string,
    medium: string,
    entry: string
  ): void {
    if (typeof window !== "undefined") {
      localStorage.setItem(USER_UTM_CAMPAIGN_KEY, campaign);
      localStorage.setItem(USER_UTM_SOURCE_KEY, source);
      localStorage.setItem(USER_UTM_MEDIUM_KEY, medium);
      localStorage.setItem(USER_UTM_ENTRY_KEY, entry);
    }
  }

  static getUtm(): {
    campaign: string;
    source: string;
    medium: string;
    entry: string;
  } | null {
    if (typeof window !== "undefined") {
      const campaignValue = localStorage.getItem(USER_UTM_CAMPAIGN_KEY);
      const sourceValue = localStorage.getItem(USER_UTM_SOURCE_KEY);
      const mediumValue = localStorage.getItem(USER_UTM_MEDIUM_KEY);
      const entryValue = localStorage.getItem(USER_UTM_ENTRY_KEY);
      return {
        campaign: campaignValue ? campaignValue : "",
        source: sourceValue ? sourceValue : "",
        medium: mediumValue ? mediumValue : "",
        entry: entryValue ? entryValue : "",
      };
    }
    return null;
  }

  static unsetUtm(): void {
    if (typeof window !== "undefined") {
      localStorage.removeItem(USER_UTM_CAMPAIGN_KEY);
      localStorage.removeItem(USER_UTM_SOURCE_KEY);
      localStorage.removeItem(USER_UTM_MEDIUM_KEY);
      localStorage.removeItem(USER_UTM_ENTRY_KEY);
    }
  }

  static setToken(token: string): void {
    if (typeof window !== "undefined") {
      localStorage.setItem(TOKEN_NAME, token);
    }
  }

  static async logOut(): Promise<void> {
    datadogRum?.removeUser();
    const clearUserInfo = () => {
      localStorage.removeItem(USER_DATA_KEY);
      localStorage.removeItem(TOKEN_NAME);
      localStorage.removeItem(WATCH_HISTORY_KEY);
      localStorage.removeItem(USER_ORG_SIGNUP_KEY);
    };
    if (typeof window !== "undefined") {
      if (localStorage.getItem(USER_G_AUTH_KEY)) {
        localStorage.removeItem(USER_G_AUTH_KEY);
        if (window.gapi && window.gapi.auth2) {
          const auth2 = window.gapi.auth2.getAuthInstance();
          await auth2.signOut();
        }
      }
      try {
        await signout();
        clearUserInfo();
      } catch {
        clearUserInfo();
      }
    }
  }

  static isActiveGroupOwner(user?: User): boolean {
    const u = user ?? this.userData();
    return Boolean(
      u &&
        user?.subscriptionGroupRole === SUB_GROUP_OWNER &&
        user?.subscriptionGroupStatus === SUB_GROUP_ACTIVE
    );
  }

  static isActiveGroupMember(user?: User): boolean {
    return (
      user?.subscriptionGroupRole === SUB_GROUP_MEMBER &&
      user?.subscriptionGroupStatus === SUB_GROUP_ACTIVE
    );
  }

  static isActiveMemberAdmin(user?: User): boolean {
    const u = user ?? this.userData();
    return Boolean(
      u &&
        user?.subscriptionGroupRole === SUB_GROUP_ADMIN &&
        user?.subscriptionGroupStatus === SUB_GROUP_ACTIVE
    );
  }

  static isEnabledAdminOwners(user?: User): boolean {
    const u = user ?? this.userData();
    return Boolean(
      u &&
        (user?.subscriptionGroupRole === SUB_GROUP_ADMIN ||
          user?.subscriptionGroupRole === SUB_GROUP_OWNER) &&
        user?.subscriptionGroupStatus === SUB_GROUP_ACTIVE
    );
  }

  static canAccessContent(user?: User | null): boolean {
    const u = user ?? this.userData();
    return Boolean(
      u &&
        ((u.subscription &&
          ["active", "cancelled"].includes(u.subscription.state)) ||
          u.vip ||
          this.isActiveGroupMember(u) ||
          this.isActiveMemberAdmin(u))
    );
  }

  static setRefferalCode(referralCode: string): void {
    if (typeof window !== "undefined") {
      localStorage.setItem(USER_REFERRAL_KEY, referralCode);
    }
  }

  static getReferralCode(): string | null {
    if (typeof window !== "undefined") {
      const referral = localStorage.getItem(USER_REFERRAL_KEY);
      return referral || null;
    }
    return null;
  }

  static removeReferralCode() {
    if (typeof window !== "undefined") {
      localStorage.removeItem(USER_REFERRAL_KEY);
    }
  }
}

const UserUtil = userUtilSingleton;

export default UserUtil;
