export enum SignUpSteps {
  SignupIos = "join",
  Signup = "signup",
  SignupOrg = "org-signup",
  PasswordAth = "password-ath",
  PasswordOrg = "password-org",
  ProfileAth = "profile-ath",
  ProfileOrg = "profile-org",
}

export const validSources = ["ios", "android"];
