/* eslint-disable @typescript-eslint/no-explicit-any */
import { isObject, keysToCamel, keysToSnake } from "helpers/humps";

export interface APIResponse<T = any> extends Response {
  data?: T;
  total?: number;
  errors?: any;
}

export interface ApiTokenResponse extends Response {
  token?: string;
  errors?: any;
}

export const API_BASE = `${process.env.API_HOST || ""}/api/v2`;
export const TOKEN_NAME = "token-v2";

type RequestConfig = Omit<RequestInit, "body"> & { body?: any };

export async function callApi(
  url: RequestInfo,
  config: RequestConfig = { headers: {} }
): Promise<APIResponse> {
  if (typeof window !== "undefined") {
    const token = localStorage.getItem(TOKEN_NAME) || null;
    if (config && token) {
      config.headers = { ...config.headers, Authorization: token };
    } else if (token && !config) {
      config = {
        headers: {
          Authorization: token,
        },
      };
    }
  }

  config.headers = {
    ...config.headers,
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  // If there is a body we need to parse and stringify it
  if (config?.body) {
    config.body = JSON.stringify(keysToSnake(config.body));
  }

  try {
    const resp = (await fetch(url, config)) as APIResponse;

    if (typeof window !== "undefined") {
      const auth = resp.headers.get("authorization");
      auth && localStorage.setItem(TOKEN_NAME, auth);
    }

    if (resp.ok && resp.data) {
      const textData = await resp.text();
      if (textData) {
        const { data: d, total } = await JSON.parse(textData);
        resp.data = keysToCamel(d);
        resp.total = total;
      }
    } else {
      let respBody = await resp.text();
      let camelRespBody: Record<"string", any> | null = null;
      if (respBody) {
        try {
          respBody = JSON.parse(respBody);
          camelRespBody = keysToCamel(respBody);
          if (isObject(camelRespBody) || Array.isArray(camelRespBody)) {
            if (!resp.ok) {
              throw camelRespBody;
            }
            if (config.method !== "DELETE") {
              return {
                ...resp,
                ...camelRespBody,
                status: 200,
                ok: true,
              } as APIResponse;
            }
          }
        } catch {}
      }
      if (!resp.ok) {
        throw camelRespBody || respBody;
      }
      return {
        ...resp,
        data: camelRespBody || respBody,
        status: 200,
        ok: true,
      } as APIResponse;
    }

    return resp;
  } catch (error: any) {
    if (!error?.errors?.[0]?.includes("Your username or password is wrong")) {
      console.error(`API ERROR for ${url}`, error);
    }
    throw error;
  }
}
