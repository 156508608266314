import { TCurrency, TCurrencyKey } from "util/subscriptionUtils";
import { Coupon } from "./coupon";

export interface Subscription {
  id: number;
  uuid?: string;
  userId: number;
  source: string;
  trialStartedAt: string | null;
  trialEndsAt: string | null;
  currentPeriodStartedAt: string;
  currentPeriodEndsAt: string;
  state: "active" | "cancelled" | "expired";
  postponeEndDate: string | null;
  postponedPeriod: string | null;
  postponedOn: string | null;
  canceledOn: string | null;
  expiresAt: string | null;
  provider: "google" | "stripe" | "apple";
  plan: Plan;
  createdAt?: string;
}
export interface SubscriptionGroup {
  id: number;
  industry: string;
  location: string;
  name: string;
  companySize: string;
}

export enum AccountType {
  Athlete = "athlete",
  Organization = "organization",
}

export interface Tier {
  upTo?: number | null;
  flatAmount?: number | null;
  unitAmount?: number | null;
  flatAmountDecimal?: string | null;
  unitAmountDecimal?: string | null;
  text?: string;
}

export interface Plan {
  id: number;
  code: string;
  cost?: string;
  currency?: TCurrencyKey;
  name: string;
  description: string | null;
  createdAt: string;
  updatedAt: string;
  trialIntervalLength: number;
  trialIntervalUnit: string;
  planIntervalLength: number;
  planIntervalUnit: string;
  amountInCents: AmountInCents;
  billingScheme?: string;
  tiers?: any;
}

export interface PlanInfo {
  type: "monthly" | "yearly";
  currency: TCurrency;
  currencyCode: TCurrencyKey;
  plan: Plan;
  monthlyAmount: number;
  yearlyAmount: number;
  monthlyAmountStr: string;
  yearlyAmountStr: string;
  yearlyDiscount: string;
  promotionalPrice: number;
  promotionalPriceStr: string;
  monthlyPromotionalPrice: number;
  monthlyPromotionalPriceStr: string;
  coupon: Coupon | null;
}

export interface PlanInfoTypes {
  monthly: PlanInfo;
  yearly: PlanInfo;
  monthlyOrg: PlanInfoOrg;
  yearlyOrg: PlanInfoOrg;
  selected: PlanInfo | null;
}

export type TPlanCode =
  | "individual-monthly-usd"
  | "individual-yearly-usd"
  | "individual-yearly-eur"
  | "individual-monthly-eur"
  | "individual-yearly-aud"
  | "individual-monthly-aud"
  | "individual-yearly-gbp"
  | "individual-monthly-gbp"
  | "individual-monthly-cad"
  | "individual-yearly-cad"
  | "individual-monthly-brl"
  | "individual-yearly-brl";

export interface PIntent {
  id: number;
  stripeId: string;
  clientSecret: string;
  type: "paymentIntent" | "setupIntent";
}

export interface StripePaymentIntent extends PIntent {
  type: "setupIntent";
  plan: {
    id: number;
    name: string;
    code: string;
    amount: number;
  };
}

export interface StripeSetupIntent extends PIntent {
  type: "setupIntent";
}

export interface AmountInCents {
  USD: number;
  EUR: number;
  AUD: number;
  CAD: number;
  BRL: number;
}

export enum SubRedirectParams {
  planId = "plan_id",
  planCode = "plan_code",
  accountType = "account_type",
  referralCode = "referral_code",
  promoCode = "campaign",
  paymentIntentClientSecret = "payment_intent_client_secret",
  setupIntentClientSecret = "setup_intent_client_secret",
  paymentIntent = "payment_intent",
  setupIntent = "setup_intent",
  redirectStatus = "redirect_status",
}

export interface PlanInfoOrg {
  type: "monthly" | "yearly";
  currency: TCurrency;
  currencyCode: TCurrencyKey;
  plan: Plan;
  monthlyAmount: number;
  yearlyAmount: number;
  monthlyAmountStr: string;
  yearlyAmountStr: string;
}
