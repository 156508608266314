import { h } from "preact";
import { useEffect } from "preact/hooks";
import { styled } from "linaria/react";
import Head from "components/shared/Head";
import { gAuthClientId } from "config";
import { googleSignin } from "services/auth";
import useUser from "hooks/userHook";
import { appRoute } from "util/routerUtil";
import mq from "style/breakpoints";

declare global {
  interface Window {
    gapi: any;
  }
}

const Container = styled.div`
  width: 100%;
  margin-top: 0;
  ${mq.minSm} {
    margin-top: 0;
  }
`;

const SigninContainer = styled.div<{ signup: boolean }>`
  display: flex;
  justify-content: center;
  cursor: pointer;
  > div {
    height: 48px !important;
    border-radius: 5px;
    margin-top: 1rem;
    width: 100% !important;
   max-width: 27rem;
  }
  > div > div {
    display: flex;
    justify-content: center;
    align-items: center;
    > span {
      width: 178px;
      margin-right: 8px;
      > span:first-child {
        visibility: ${({ signup }) => (signup ? "hidden" : "visible")};
      }
      > span:first-child:before {
        position: ${({ signup }) => (signup ? "relative" : "absolute")};
        visibility: ${({ signup }) => (signup ? "visible" : "hidden")};
        content: "Sign up with Google"};
      }
    }
  }
  span {
    font-size: 1.5em;
    color: #000;
  }
`;

export const googleSignInAlreadyLoaded = () =>
  document.getElementById("GoogleSignIn") !== null;

export const appendGoogleSigninSdkScript = (init?: (e: any) => any) => {
  const googleAuthScriptTag = document.createElement("script");
  googleAuthScriptTag.type = "text/javascript";
  googleAuthScriptTag.id = "GoogleSignIn";
  if (init) {
    googleAuthScriptTag.onload = init;
  }
  googleAuthScriptTag.src = "https://apis.google.com/js/platform.js";
  const s = document.getElementsByTagName("script")[0];
  s && s.parentNode && s.parentNode.insertBefore(googleAuthScriptTag, s);
};

export const GoogleLogin = ({ type }: any) => {
  const signup = type === "signup";
  const init = () => {
    if (window.gapi) {
      window.gapi.signin2.render("google-signin", {
        scope: "profile email",
        longtitle: true,
        theme: "light",
        onsuccess: onGoogleSignIn,
        onfailure: onFailure,
      });
    }
  };

  const { setUser } = useUser();

  const onFailure = (e) => console.log("G signin failure", e);

  const onGoogleSignIn = async (googleUser: any) => {
    const profile = googleUser.getBasicProfile();
    const authResponse = googleUser.getAuthResponse();
    const gUser = {
      googleUser: {
        email: profile.getEmail(),
        googleId: profile.getId(),
        firstName: profile.getGivenName(),
        lastName: profile.getFamilyName(),
        idToken: authResponse.id_token,
      },
    };
    const { data: user } = await googleSignin(gUser);
    if (user) {
      setUser(user);
      localStorage.setItem("gAuth", "true");
      if (
        !user.subscription &&
        user.subscriptionGroupRole === "sub_group_owner"
      ) {
        appRoute("/org-signup");
      } else if (!user.subscription) {
        appRoute("/signup/ath-payment");
      } else {
        appRoute("/");
      }
    }
  };

  useEffect(() => {
    if (!googleSignInAlreadyLoaded()) {
      appendGoogleSigninSdkScript(init);
    } else {
      init();
    }
  }, []);

  return (
    <Container>
      <Head>
        <meta name="google-signin-client_id" content={gAuthClientId} />
      </Head>
      <SigninContainer
        className="g-signin2"
        id="google-signin"
        signup={signup}
      />
    </Container>
  );
};
