import { h, FunctionalComponent } from "preact";
import { MouseEvent } from "react";
import { styled } from "linaria/react";

import { appRoute } from "util/routerUtil";
import Colors from "style/colors";
import Text from "components/shared/Text";
import Icon, { IconTypes } from "components/shared/Icon";
import Loader from "components/layout/Loader";
import { cx } from "linaria";

export interface ButtonProps {
  text?: string;
  icon?: IconTypes | null;
  disabled?: boolean;
  inactive?: boolean;
  secondary?: boolean;
  pillText?: string;
  loading?: boolean;
  size: "L" | "M" | "S";
  handleClick?: (
    e:
      | MouseEvent<HTMLButtonElement>
      | h.JSX.TouchEventHandler<HTMLButtonElement>
  ) => void | null | Promise<void>;
  onTouchStart?: (e) => void;
  onTouchMove?: (e) => void;
  onTouchEnd?: (e) => void;
  href?: string;
  analytics?: { event: string; properties?: Record<string, unknown> };
  type?: "submit" | "button";
  iconRight?: boolean;
  padding?: string;
  iconPadding?: any;
  dataTestId?: string;
  unwrapped?: boolean;
  id?: string;
}

const Pill = styled.div`
  background-color: ${Colors.bgDark0};
  color: ${Colors.txtLight};
  border-radius: 50%;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  margin-left: 0.25rem;
`;

const ButtonTag = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  img {
    position: absolute;
  }
  div {
    display: flex;
    visibility: ${({ loading }) => (loading ? "hidden" : "visible")};
  }
  span:first-child {
    padding-right: ${({ text, icon }) => (text && icon ? "0.5rem" : "0")};
  }
  border-radius: ${({ icon, text }) => (icon && !text ? "50%" : "30px")};
  border: none;
  font-weight: 500;
  color: ${Colors.txtDark};
  padding: ${({ icon, size, text, iconPadding, padding }) =>
    icon && !text ? iconPadding[size] : padding};
  background-color: ${Colors.bgDark0};
  color: ${Colors.bgDark0};
  cursor: ${({ disabled, loading }) =>
    disabled ? "not-allowed" : loading ? "auto" : "pointer"};
  transition: opacity 0.25s ease-in-out;
  :hover:active {
    background-color: ${Colors.bgDark0};
  }
  :disabled,
  &.inactive {
    color: ${Colors.bgLight2} !important;
    background-color: #e2e2e2 !important;
  }
  :hover {
    opacity: 0.9;
  }
  &.secondary {
    color: ${Colors.txtLight};
    background-color: ${Colors.bgDark2};
    :hover:active {
      background-color: ${Colors.bgDark3};
    }
  }
`;

const Button: FunctionalComponent<ButtonProps> = ({
  text,
  icon,
  disabled,
  inactive,
  secondary,
  loading,
  size,
  handleClick,
  onTouchStart,
  onTouchMove,
  onTouchEnd,
  href,
  pillText,
  type,
  iconRight,
  iconPadding = {
    L: "1.25rem",
    M: "1rem",
    S: "0.5rem",
  },
  dataTestId = "",
  unwrapped = true,
  id,
}: ButtonProps) => {
  const verticalPadding = {
    L: 0.9375,
    M: 0.8125,
    S: 0.375,
  };

  const padding = `${verticalPadding[size]}rem ${
    verticalPadding[size] * 2.5
  }rem`;

  const iconSize = ["S", "M"].includes(size) ? "S" : "M";

  const _handleClick = (e) => {
    if (loading || disabled) return;

    if (href) return appRoute(href);

    handleClick && handleClick(e);
  };

  const _onTouchEnd = (e) => {
    if (loading || disabled) return;
    if (href) return appRoute(href);
    onTouchEnd && onTouchEnd(e);
  };

  const _onTouchMove = (e) => {
    onTouchMove && onTouchMove(e);
  };

  const _onTouchStart = (e) => {
    onTouchStart && onTouchStart(e);
  };

  const events = {
    onMouseUp: (href || handleClick) && _handleClick,
    onTouchStart: onTouchStart && _onTouchStart,
    onTouchMove: onTouchMove && _onTouchMove,
    onTouchEnd: onTouchEnd && _onTouchEnd,
  };

  const filteredEvents = Object.keys(events).reduce((acc, key) => {
    if (events[key]) {
      acc[key] = events[key];
    }
    return acc;
  }, {});

  return (
    <ButtonTag
      {...filteredEvents}
      className={cx(secondary && "secondary", inactive && "inactive")}
      href={href}
      text={text}
      icon={icon}
      size={size}
      disabled={disabled}
      loading={loading}
      aria-label={text || icon?.toString().replace("--", " ")}
      type={type || "submit"}
      padding={padding}
      iconPadding={iconPadding}
      data-testid={dataTestId}
      id={id}
    >
      {loading && (
        <Loader
          isLoading={Boolean(loading)}
          size={size}
          unwrapped={unwrapped}
        />
      )}
      <div>
        {icon && !iconRight && <Icon name={icon} size={iconSize} />}
        {text && (
          <Text isAction size={size}>
            {text}
          </Text>
        )}
        {icon && iconRight && <Icon name={icon} size={iconSize} />}
      </div>
      {!!pillText && (
        <Pill>
          <Text isAction size={"XS"}>
            {pillText}
          </Text>
        </Pill>
      )}
    </ButtonTag>
  );
};

export default Button;
