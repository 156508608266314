/* eslint-disable prefer-spread */
/* eslint-disable no-var */
/* eslint-disable prefer-rest-params */
import { FunctionalComponent } from "preact";
import { useEffect } from "preact/hooks";

declare global {
  interface Window {
    rdt: any;
  }
}

export const getReddit = () =>
  typeof window !== "undefined" ? window?.rdt : undefined;

function loadReddit() {
  if (typeof window !== "undefined") {
    +(function (w, d) {
      if (!w.rdt) {
        var p: any = (w.rdt = function () {
          p.sendEvent;
          p.sendEvent?.apply(p, arguments);
          p.callQueue?.push(arguments);
        });
        p.callQueue = [];
        var t = d.createElement("script");
        (t.src = "https://www.redditstatic.com/ads/pixel.js"), (t.async = !0);
        var s: any = d.getElementsByTagName("script")[0];
        s.parentNode.insertBefore(t, s);
      }
    })(window, document);
    window?.rdt("init", "t2_awdywijpt", {
      optOut: false,
      useDecimalCurrencyValues: true,
    });
  }
}

const RedditConfiguration: FunctionalComponent = () => {
  useEffect(() => {
    if (getReddit()) {
      return;
    }
    loadReddit();
  }, []);
  return null;
};
export default RedditConfiguration;
