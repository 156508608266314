import { API_BASE, APIResponse, callApi } from "./api";

interface SsoToken {
  token?: string;
}

export async function genSsoToken(): Promise<APIResponse<SsoToken>> {
  try {
    return await callApi(`${API_BASE}/current_user/sso_token`, {
      method: "POST",
    });
  } catch (e) {
    throw e;
  }
}
