import { FunctionalComponent, h, Fragment } from "preact";
import { useState } from "preact/hooks";
import { styled } from "linaria/react";
import Button from "components/shared/Button";
import Colors from "style/colors";
import useSubscriptionGroup from "hooks/subscriptionGroupHooks";
import mq from "style/breakpoints";
import { DisplayOverlay } from "style/layout";
import Icon, { IconTypes } from "components/shared/Icon";
import { RenderLogo } from "components/layout/RenderLogo";

const Container = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10005;
  width: 343px;
  height: auto;
  max-height: max-content;
  background: ${Colors.bgLight2};
  margin: auto;
  border-radius: 16px;

  ${mq.minSm} {
    width: 636px;
  }
`;

const InvitationSentContainer = styled(Container)`
  > div {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;

    ${mq.minSm} {
      padding: 40px;
    }

    h1 {
      color: #000000;
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      text-align: center;
    }

    p {
      color: #444444;
      text-align: center;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
    }

    > button {
      width: 100%;
      height: 56px;
      border-radius: 9999px;
      span {
        color: #ffffff;
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
      }
    }
  }
`;

const InviteMembersContent = styled.div`
  padding: 0;
  width: 100%;
  padding: 40px;

  h1 {
    color: #000000;
    font-family: "Neusa Next Pro";
    font-size: 28px;
    font-weight: 700;
    line-height: 36px;
    margin-bottom: 40px;
  }

  > div > button {
    width: 100%;
    height: 56px;
    background: ${Colors.bgDark0};
    border-radius: 9999px;
    border: none;
    cursor: pointer;
    display: inline-flex;

    > div > span {
      color: #ffffff;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }

    :hover {
      opacity: 0.9;
    }
  }
`;

const Close = styled.div`
  position: absolute;
  right: 24px;
  top: 24px;
  cursor: pointer;

  span {
    font-weight: 600;
  }
`;

const MembersInvite = styled.div`
  border-radius: 8px;
  border: 1px solid #8e8e93;
  padding: 16px 40px 16px 20px;
  margin-bottom: 40px;
  max-height: 45vh;
  overflow-y: auto;
  overflow-x: hidden;

  @-moz-document url-prefix() {
    scrollbar-width: none;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background: transparent;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: #333333;
    border-radius: 6px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #333333;
  }

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    span {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
      opacity: 0.34;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    > button {
      background: transparent;

      :hover:active {
        background: transparent;
      }
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px !important;
  border-top: 1px dashed ${Colors.bgGrey2} !important;
  margin: 16px 0 !important;
  padding: 0 !important;
`;

interface BulkActionBarModalProps {
  onClose?: () => void;
  type?: string;
  setRefetch?: () => void;
  listMember: any;
}

const BulkActionBarModal: FunctionalComponent<BulkActionBarModalProps> = ({
  onClose,
  type,
  setRefetch,
  listMember,
}: BulkActionBarModalProps) => {
  const { X } = IconTypes;
  const { resendSubscriptionGroupInvite, deleteSubscriptionGroupInvite } =
    useSubscriptionGroup();
  const [loading, setLoading] = useState(false);
  const [memberList, setMemberList] = useState<any[]>(listMember);
  const resendInvite = type === "resend";
  const deleteInvite = type === "delete";
  const [isModalResend, setIsModalResend] = useState(false);
  const [isModalDelete, setIsModalDelete] = useState(false);

  const handleSendInvite = async () => {
    setLoading(true);

    const inviteIds = memberList
      ?.filter((member) => member.status === "invite_pending")
      .map((item) => item.id);

    try {
      await resendSubscriptionGroupInvite({ inviteIds });
      setIsModalResend(true);
      setLoading(false);
    } catch {
      setLoading(false);
    }
  };

  const handleDeleteInvite = async () => {
    setLoading(true);
    const deleteIds = memberList?.map((item) => item.id);
    try {
      await deleteSubscriptionGroupInvite({ inviteIds: deleteIds });
      setLoading(false);
      setIsModalDelete(true);
      setRefetch?.();
    } catch {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      {isModalResend || isModalDelete ? (
        <InvitationSentContainer>
          <div>
            {RenderLogo("white-background-sm")}
            <h1>{deleteInvite ? "Members Deleted" : "Invite Sent"}</h1>
            <p>
              {deleteInvite
                ? "These members will no longer have access to pliability."
                : "Your members will receive an email shortly to join."}
            </p>
            <Button size="S" text={"Done"} handleClick={onClose} />
          </div>
        </InvitationSentContainer>
      ) : (
        <Container>
          <Close onClick={onClose}>
            <Icon name={X} />
          </Close>
          <InviteMembersContent>
            <h1>{resendInvite ? "Resend Invite" : "Delete users"}</h1>
            {!!memberList?.length && (
              <MembersInvite>
                {memberList?.map((member, index) => (
                  <Fragment key={index}>
                    <div>
                      <span>{member.email}</span>
                      <Button
                        size="S"
                        text="Remove"
                        handleClick={() => {
                          const filterEmail = memberList?.filter(
                            (em) => em.email !== member.email
                          );

                          if (memberList?.length === 1) {
                            onClose?.();
                          } else {
                            setMemberList(filterEmail);
                          }
                        }}
                      />
                    </div>
                    {memberList?.length > 0 &&
                      index !== memberList?.length - 1 && <Divider />}
                  </Fragment>
                ))}
              </MembersInvite>
            )}
            <div style={{ width: "100%" }}>
              <Button
                size="S"
                text={type === "resend" ? "Resend Invite" : "Delete"}
                handleClick={() => {
                  if (type === "resend") {
                    handleSendInvite();
                  } else {
                    handleDeleteInvite();
                  }
                }}
                loading={loading}
                disabled={!memberList?.length}
                unwrapped={true}
                type="button"
              />
            </div>
          </InviteMembersContent>
        </Container>
      )}
      <DisplayOverlay
        className="overlay"
        background="#000000"
        style={{ opacity: 0.7, zIndex: 10004 }}
        onClick={onClose}
      />
    </Fragment>
  );
};

export default BulkActionBarModal;
