import { useSelector, useAction } from "@preact-hooks/unistore";
import { useEffect } from "preact/hooks";
import { datadogRum } from "@datadog/browser-rum";

import UserUtil, { USER_G_AUTH_KEY } from "util/userUtil";
import { User } from "models/user";
import actions from "store/actions";
import {
  signup as _signup,
  signin as _signin,
  requestResetPassword as _requestResetPassword,
  resetPassword as _resetPassword,
  verifyEmail as _verifyEmail,
  checkEmailExists as _checkEmailExists,
} from "services/auth";
import {
  editUser as _editUser,
  getUser,
  deleteCurrentAvatar as _deleteCurrentAvatar,
  UserUpdateBody,
} from "services/user";
import {
  appendGoogleSigninSdkScript,
  googleSignInAlreadyLoaded,
} from "components/auth/GoogleSignIn";
import { appRoute } from "util/routerUtil";
import { keysToSnake } from "helpers/humps";

interface UserHook {
  user: User | null;
  signup: (opts: SignupOpts) => Promise<User | undefined>;
  signin: (opts: SigninOpts) => Promise<User | undefined>;
  setUser: (opts: User) => void;
  requestResetPassword: (email: string) => void;
  resetPassword: (opts: ResetPasswordOpts) => void;
  verifyEmail: (opts: EmailOpts) => any;
  checkEmailExists: (opts: EmailOpts) => any;
  errorRedirect: (error: { error: string }) => Promise<void> | undefined;
  logout(param?: string): Promise<void>;
  editUser: (opts: UserUpdateBody) => any;
  refreshUser: () => void;
  deleteCurrentAvatar: () => void;
  getIntercomUser(): IntercomUser | null;
}

interface IntercomUser {
  name?: string | null;
  userId?: string | null;
  email?: string | null;
  userState?: string | null;
  userRole?: string | null;
  businessName?: string | null;
  phone?: string | null;
  paypalEmail?: string | null;
  address1?: string | null;
  address2?: string | null;
  city?: string | null;
  state?: string | null;
  zip?: string | null;
  country?: string | null;
  website?: string | null;
  ambassadorReferrer?: string | null;
  ambassadorCampaign?: string | null;
  planCode?: string | null;
  userHash?: string | null;
}

export interface SignupOpts {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  tos?: boolean;
  confirmSuccessUrl: string;
  source?: string;
  inviteCode?: string;
  utmCampaign?: string;
  utmSource?: string;
  utmMedium?: string;
  utmEntryUrl?: string;
  companyRole?: string;
}

interface SigninOpts {
  email: string;
  password: string;
  rememberMe: boolean;
}

interface ResetPasswordOpts {
  password: string;
  passwordConfirmation: string;
  resetPasswordToken: string;
}

interface EmailOpts {
  email: string;
}

let dataDogUserIdentified = false;
let editingUser = false;

export default function useUser(): UserHook {
  const { user } = useSelector("user");
  const setUserAction = useAction(actions.setUser);
  const logoutAction = useAction(actions.logOut);

  const setUser = (u: User | null) => {
    if (u) {
      setUserAction(u);
      UserUtil.save(u);
      if (!dataDogUserIdentified) {
        datadogRum.setUser(
          keysToSnake({
            id: u.id.toString(),
            email: u.email,
            name: `${u.firstName} ${u.lastName}`,
            planCode: u?.subscription?.plan?.code,
            planProvider: u?.subscription?.provider,
            subscriptionState: u?.subscription?.state,
          })
        );
        dataDogUserIdentified = true;
      }
    }
  };

  if (!user && UserUtil.isLoggedIn()) {
    if (localStorage.getItem(USER_G_AUTH_KEY) && !googleSignInAlreadyLoaded()) {
      appendGoogleSigninSdkScript();
    }
    setUser(UserUtil.userData());
  }

  const signup = async (opts: SignupOpts) => {
    try {
      const result = await _signup(opts);
      setUserAction(result.data);
      UserUtil.save(result.data);
      return result.data;
    } catch (error) {
      throw error;
    }
  };

  const signin = async (opts: SigninOpts) => {
    try {
      const result = await _signin(opts);
      setUser(result.data as User);
      return result.data;
    } catch (error) {
      throw error;
    }
  };

  const refreshUser = async () => {
    try {
      const result = await getUser();
      if (result.data) {
        setUser(result.data);
      }
    } catch (error: any) {
      errorRedirect(error);
      throw error;
    }
  };

  const requestResetPassword = async (email: string) => {
    try {
      await _requestResetPassword(email);
    } catch (error: any) {
      throw error;
    }
  };

  const resetPassword = async (opts: ResetPasswordOpts) => {
    try {
      await _resetPassword(opts);
    } catch (error: any) {
      throw error;
    }
  };

  const verifyEmail = async (opts: EmailOpts) => {
    try {
      const result = await _verifyEmail(opts);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const checkEmailExists = async (opts: EmailOpts) => {
    try {
      const result = await _checkEmailExists(opts);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const logout = async (param?: string) => {
    await UserUtil.logOut();
    appRoute(`/signin${param ?? ""}`);
    return logoutAction();
  };

  const errorRedirect = (error: { error: string }) => {
    if (
      error &&
      [
        "You need to sign in or sign up before continuing.",
        "No payload or user id",
      ].includes(error?.error)
    ) {
      return logout();
    }
  };

  const editUser = async (opts: UserUpdateBody) => {
    if (!editingUser) {
      try {
        editingUser = true;
        const result = await _editUser(opts);
        setUserAction(result.data);
        UserUtil.save(result.data);
        editingUser = false;
      } catch (error: any) {
        errorRedirect(error);
        throw error;
      }
    }
  };

  const deleteCurrentAvatar = async () => {
    try {
      await _deleteCurrentAvatar();
    } catch (error: any) {
      errorRedirect(error);
      return error;
    }
  };

  const getIntercomUser = (): IntercomUser | null => {
    if (!user) return null;
    const iu = {
      name: `${user?.firstName ?? ""} ${user?.lastName ?? ""}`,
      userId: user?.pid ?? null,
      email: user?.email ?? null,
      userState: user?.state ?? null,
      userRole: user?.role ?? null,
      businessName: user?.businessName ?? null,
      phone: user?.phone ?? null,
      paypalEmail: user?.paypalEmail ?? null,
      address1: user?.address1 ?? null,
      address2: user?.address2 ?? null,
      city: user?.city ?? null,
      state: user?.stateAbbr ?? null,
      zip: user?.zipCode ?? null,
      country: user?.country ?? null,
      website: user?.website ?? null,
      ambassadorReferrer: user?.referralCode ?? null,
      ambassadorCampaign: user?.campaignUid ?? null,
      planCode: user?.subscription?.plan?.code ?? null,
      userHash: user?.intercomHmacWeb ?? null,
    };

    return iu;
  };

  return {
    user,
    signup,
    signin,
    setUser,
    requestResetPassword,
    resetPassword,
    verifyEmail,
    checkEmailExists,
    logout,
    editUser,
    refreshUser,
    deleteCurrentAvatar,
    getIntercomUser,
    errorRedirect,
  };
}
