import { useSelector, useAction } from "@preact-hooks/unistore";
import { useEffect, useState } from "preact/hooks";

import actions from "store/actions";
import {
  SubscriptionGroup,
  SubscriptionGroupAnalytics,
  SubscriptionGroupInvite,
} from "models/subscriptionGroup";

import {
  getSubscriptionGroup as _getSubscriptionGroup,
  getSubscriptionGroupAnalytics as _getSubscriptionGroupAnalytics,
  updateSubscriptionGroup as _updateSubscriptionGroup,
  getSubscriptionGroupInvites as _getSubscriptionGroupInvites,
  deleteSubscriptionGroupInvite as _deleteSubscriptionGroupInvite,
  resendSubscriptionGroupInvite as _resendSubscriptionGroupInvite,
  createSubscriptionGroupInvite as _createSubscriptionGroupInvite,
  getMemberListLazyLoading as _getMemberListLazyLoading,
  validateGroupInviteCode as _validateGroupInviteCode,
  manageAdminSubscriptionGroups as _manageAdminSubscriptionGroups,
  ValidateGroupInviteCodeResponse,
  getSubscriptionGroupAdmin as _getSubscriptionGroupAdmin,
} from "services";
import { Subscription } from "models/subscription";
interface SubscriptionGroupHook {
  getSubscriptionGroup: () => SubscriptionGroup | null;
  getSubscriptionGroupAdmin: (
    members: SubscriptionGroupInvite
  ) => Subscription | null;
  getSubscriptionGroupAnalytics: () => SubscriptionGroupAnalytics | null;
  getSubscriptionGroupInvites: (
    refetch?: boolean
  ) => SubscriptionGroupInvite | null;
  updateSubscriptionGroup: (opts) => void;
  createSubscriptionGroupInvite: (
    opts,
    memberNumbers?
  ) => Promise<SubscriptionGroupInvite[] | undefined>;
  resendSubscriptionGroupInvite: (opts) => Promise<string>;
  manageAdminSubscriptionGroups: (opts) => Promise<any | undefined>;
  deleteSubscriptionGroupInvite: (opts) => Promise<string | undefined>;
  validateGroupInviteCode: (
    couponCode: string
  ) => Promise<ValidateGroupInviteCodeResponse | null>;
  getMemberListLazyLoading: (opts, deleted) => any;
}

export default function useSubscriptionGroup(): SubscriptionGroupHook {
  const setSubscriptionGroupAction = useAction(actions.setSubscriptionGroup);
  const setSubscriptionGroupAdminAction = useAction(
    actions.setSubscriptionGroupAdmin
  );
  const setSubscriptionGroupAnalyticsAction = useAction(
    actions.setSubscriptionGroupAnalytics
  );
  const setSubscriptionGroupInvitesAction = useAction(
    actions.setSubscriptionGroupInvites
  );
  const setSearchOptsMembersAction = useAction(actions.setSearchOptsMembers);
  const setSearchResultsMembersAction = useAction(
    actions.setSearchResultsMembers
  );
  const { searchOptsMembers, searchResultsMembers } = useSelector(
    "searchOptsMembers, searchResultsMembers"
  );

  const getSubscriptionGroup = (): SubscriptionGroup | null => {
    const { subscriptionGroup } = useSelector("subscriptionGroup");
    useEffect(() => {
      (async () => {
        try {
          const result = await _getSubscriptionGroup();
          setSubscriptionGroupAction(result.data);
        } catch (error) {
          console.log("ERROR", error);
          return error;
        }
      })();
    }, []);
    return subscriptionGroup;
  };

  const getSubscriptionGroupAdmin = (
    members?: SubscriptionGroupInvite
  ): Subscription | null => {
    const { subscriptionGroupAdmin } = useSelector("subscriptionGroupAdmin");

    useEffect(() => {
      (async () => {
        try {
          const result = await _getSubscriptionGroupAdmin();
          setSubscriptionGroupAdminAction(result.data ?? null);
        } catch (error) {
          console.log("ERROR", error);
          return error;
        }
      })();
    }, [members]);
    return subscriptionGroupAdmin;
  };

  const getSubscriptionGroupAnalytics =
    (): SubscriptionGroupAnalytics | null => {
      const { subscriptionGroupAnalytics } = useSelector(
        "subscriptionGroupAnalytics"
      );
      useEffect(() => {
        (async () => {
          try {
            const result = await _getSubscriptionGroupAnalytics();
            setSubscriptionGroupAnalyticsAction(result.data);
          } catch (error) {
            console.log("ERROR", error);
            return error;
          }
        })();
      }, []);
      return subscriptionGroupAnalytics;
    };

  const getSubscriptionGroupInvites = (
    refetch
  ): SubscriptionGroupInvite | null => {
    const { subscriptionGroupInvites } = useSelector(
      "subscriptionGroupInvites"
    );

    useEffect(() => {
      (async () => {
        try {
          const result = await _getSubscriptionGroupInvites();
          if (result?.data?.members)
            setSubscriptionGroupInvitesAction(result.data);
        } catch (error) {
          console.log("ERROR", error);
          return error;
        }
      })();
    }, [refetch]);
    return subscriptionGroupInvites;
  };

  const getMemberListLazyLoading = (opts?: any, deleted?: boolean): any => {
    useEffect(() => {
      if (!searchResultsMembers || deleted) return;
      !deleted &&
        setSearchOptsMembersAction([
          ...searchOptsMembers,
          ...searchResultsMembers.data,
        ]);
    }, [searchResultsMembers]);

    useEffect(() => {
      if (opts && Object.keys(opts).length) {
        (async () => {
          try {
            const result = await _getMemberListLazyLoading(opts);

            if (result !== null) {
              setSearchResultsMembersAction({
                data: result?.data?.members?.data,
                total: result?.data?.members?.meta?.numberOfItems,
                activeCount: result?.data?.activeCount,
                pendingCount: result?.data?.pendingCount,
              });
            }
          } catch (error) {
            console.log("ERROR", error);
            return error;
          }
        })();
      }
    }, [opts && JSON.stringify(opts)]);

    return searchResultsMembers;
  };

  const updateSubscriptionGroup = async (opts) => {
    try {
      const result = await _updateSubscriptionGroup(opts);
      setSubscriptionGroupAction(result.data);
    } catch (error) {
      console.log("ERROR", error);
      throw error;
    }
  };

  const createSubscriptionGroupInvite = async (opts) => {
    try {
      const result = await _createSubscriptionGroupInvite(opts);
      const members = result?.data?.members?.data;
      setSearchOptsMembersAction(members);
      return result?.data?.members?.data;
    } catch (error) {
      console.log("ERROR", error);
      throw error;
    }
  };

  const resendSubscriptionGroupInvite = async (opts) => {
    try {
      const result = await _resendSubscriptionGroupInvite(opts);
      return result?.data?.message || "";
    } catch (error) {
      console.log("ERROR", error);
      throw error;
    }
  };

  const deleteSubscriptionGroupInvite = async (opts) => {
    try {
      const result = await _deleteSubscriptionGroupInvite(opts);
      return result?.data?.message || "";
    } catch (error) {}
  };

  const validateGroupInviteCode = async (inviteCode: string) => {
    try {
      const result = await _validateGroupInviteCode(inviteCode);
      return result?.data ? result.data : null;
    } catch (error) {
      console.log("ERROR", error);
      return null;
    }
  };

  const manageAdminSubscriptionGroups = async (opts) => {
    try {
      await _manageAdminSubscriptionGroups(opts);
    } catch (error) {
      console.log("ERROR", error);
      return null;
    }
  };

  return {
    validateGroupInviteCode,
    getSubscriptionGroup,
    getSubscriptionGroupAdmin,
    getSubscriptionGroupAnalytics,
    updateSubscriptionGroup,
    getSubscriptionGroupInvites,
    createSubscriptionGroupInvite,
    resendSubscriptionGroupInvite,
    deleteSubscriptionGroupInvite,
    getMemberListLazyLoading,
    manageAdminSubscriptionGroups,
  };
}
