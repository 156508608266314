/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

const isArray = (a) => {
  return Array.isArray(a);
};

export const isObject = (o) => {
  return o === Object(o) && !isArray(o) && typeof o !== "function";
};

export const toCamel = (s: string): string => {
  return s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace("-", "").replace("_", "");
  });
};

export const toSnake = (s: string): string => {
  return s.replace(/([A-Z])/g, "_$1").toLowerCase();
};

export const toKebab = (s: string): string => {
  return s.replace(/([A-Z])/g, "-$1").toLowerCase();
};

export const keysToCamel = (o: any): any => {
  if (isObject(o)) {
    const n = {};
    Object.keys(o).forEach((k: string) => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });
    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return keysToCamel(i);
    });
  }
  return o;
};

export const keysToSnake = (o: any): any => {
  if (isObject(o)) {
    const n = {};
    Object.keys(o).forEach((k: string) => {
      n[toSnake(k)] = keysToSnake(o[k]);
    });
    return n;
  } else if (isArray(o)) {
    return o.map((i: any) => {
      return keysToSnake(i);
    });
  }
  return o;
};
