import { FunctionalComponent, h } from "preact";
import { useRef } from "preact/hooks";
import { styled } from "linaria/react";
import Colors from "style/colors";
import Icon, { IconTypes } from "components/shared/Icon";

const SearchIcon = styled.div`
  position: absolute;
  left: 16px;
  top: 15px;
  height: 100%;
  padding: 0;
  order: 0;
  display: flex;

  span {
    font-size: 20px;
    height: 20px;
    ::before {
      color: #8e8e93;
    }
  }
`;

const ClearIcon = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 13px;
  right: 16px;
  height: 100%;
  padding: 0;
  order: 0;
  display: flex;
  background: ${Colors.bgLight0};
  width: 20px;
  height: 20px;
  border-radius: 100px;
  z-index: 100;
  cursor: pointer;

  span {
    font-size: 7px;
    font-weight: 700;
    ::before {
      color: #1a1a1a;
    }
  }
`;

const InputContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  max-width: 512px;

  input[type="text"] {
    caret-color: ${Colors.txtGrey3};
    border: none;
    font-family: "Gilroy";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    height: 48px;
    padding: 16px 0 16px 45px;
    color: ${Colors.txtGrey3};
    border-radius: 100px;
    background: ${Colors.bgLight3};

    ::placeholder {
      color: ${Colors.txtGrey3};
      font-size: 16px;
    }
  }
`;

interface SearchInputProps {
  value: string;
  onChange: (e: h.JSX.TargetedEvent<HTMLInputElement, Event>) => void;
  onClear: () => void;
  placeholder?: string;
}

const SearchInput: FunctionalComponent<SearchInputProps> = ({
  value,
  onChange,
  onClear,
  placeholder = "Search...",
}) => {
  const inputRef = useRef<HTMLInputElement>();

  return (
    <InputContainer>
      <SearchIcon>
        <Icon name={IconTypes.Search} size="XS" color={"white"} />
      </SearchIcon>
      <input
        ref={inputRef as React.RefObject<HTMLInputElement>}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        type="text"
      />
      {value && (
        <ClearIcon onClick={onClear}>
          <Icon name={IconTypes.X} size="S" color={"white"} />
        </ClearIcon>
      )}
    </InputContainer>
  );
};

export default SearchInput;
