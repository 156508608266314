import { useEffect, useState } from "preact/hooks";
import {
  upsertVideoWatch as _upsertVideoWatch,
  getUserWatchHistory as _getUserWatchHistory,
} from "services";
import dayjs from "dayjs";
import { WatchHistory, DayActivity, WatchTime } from "models/watchHistory";
import { Video } from "models/video";

export const WATCH_HISTORY_KEY = "userWatchHistory";

type TrackVideoProps = {
  id?: number;
  percent: number;
  seconds: number;
  video: Video;
  timeZone: string | null | undefined;
  completed: boolean;
};

interface WatchHistoryHook {
  userActivity: WatchHistory;
  trackVideo(props: TrackVideoProps): void;
  fetchUserWatchHistory(
    startDate: dayjs.Dayjs,
    endDate: dayjs.Dayjs,
    refetch?: boolean,
    isDelete?: boolean
  ): WatchHistory | null | undefined;
  lastDaysOfHistory(daysAgo: number): WatchHistory;
}

const emptyState = {
  timeByDay: {},
  watchHistory: [],
};

export function useWatchHistory(): WatchHistoryHook {
  const [userActivity, setUserActivity] = useState<WatchHistory>(
    readWatchHistoryCache()
  );

  const trackVideo = ({
    id,
    percent,
    seconds,
    timeZone,
    video,
    completed,
  }: TrackVideoProps): void => {
    try {
      const d = dayjs();
      const dateStr = d.format("YYYY-MM-DD");
      const watchedAt = d.valueOf();
      const whEntry: DayActivity = {
        video,
        watchedAt,
        seconds: Math.round(seconds),
        percent,
        date: dateStr,
        watchTimes: {
          [watchedAt]: { timeZone, seconds } as WatchTime,
        },
      };
      const wh = {
        watchHistory: [whEntry],
        timeByDay: { [dateStr]: seconds },
      };
      const mwh = mergeWatchHistory(wh, userActivity);
      cacheWatchHistory(mwh);
      setUserActivity(mwh);

      _upsertVideoWatch({
        id,
        videoId: video.id,
        percent,
        seconds,
        completed,
      });
    } catch (error) {}
  };

  let fetchingActivity = false;

  const fetchUserWatchHistory = (
    startDate = dayjs().startOf("month"),
    endDate = dayjs(),
    refetch = false,
    deleteActivity = false
  ) => {
    const [isRefetch, setIsRefetch] = useState(false);

    useEffect(() => {
      if (!fetchingActivity) {
        fetchingActivity = true;
        (async () => {
          try {
            if (endDate.isAfter(dayjs())) {
              endDate = dayjs();
            }
            setIsRefetch(true);
            const result = await _getUserWatchHistory(startDate, endDate);
            setIsRefetch(false);
            const watchHistory = result?.watchHistory ? result : emptyState;
            const mergedWatchHistory = mergeWatchHistory(
              watchHistory,
              userActivity
            );

            if (deleteActivity) {
              cacheWatchHistory(watchHistory);
              setUserActivity(watchHistory);
              fetchingActivity = false;
            } else {
              cacheWatchHistory(mergedWatchHistory);
              setUserActivity(mergedWatchHistory);
              fetchingActivity = false;
            }
          } catch (error) {
            setIsRefetch(false);
            fetchingActivity = false;
            return null;
          }
        })();
      }
    }, [startDate, refetch]);
    return userActivity;
  };

  const lastDaysOfHistory = (daysAgo: number) => {
    if (readWatchHistoryCache()) {
      const d = dayjs().subtract(daysAgo, "days");
      return {
        timeByDay: {},
        watchHistory: readWatchHistoryCache()?.watchHistory.filter((w) =>
          dayjs(w.date).isAfter(d)
        ),
      };
    }
    return emptyState;
  };

  return { userActivity, trackVideo, fetchUserWatchHistory, lastDaysOfHistory };
}

function cacheWatchHistory(wh: WatchHistory): void {
  if (typeof window !== "undefined") {
    try {
      localStorage.setItem(WATCH_HISTORY_KEY, JSON.stringify(wh));
    } catch (err) {}
  }
}

function readWatchHistoryCache(): WatchHistory {
  if (typeof window !== "undefined") {
    const cwh = localStorage.getItem(WATCH_HISTORY_KEY);
    const watchHistory = cwh ? JSON.parse(cwh) : emptyState;
    return watchHistory;
  }
  return emptyState;
}

const mergeWatchHistory = (
  updatedHistory: WatchHistory,
  existingHistory: WatchHistory
): WatchHistory => {
  if (updatedHistory?.watchHistory.length === 0) {
    return existingHistory;
  } else if (
    updatedHistory &&
    Object.keys(updatedHistory?.timeByDay)?.length > 0 &&
    Object.keys(existingHistory?.timeByDay)?.length &&
    dayjs(Object.keys(updatedHistory.timeByDay)[0]).isBefore(
      Object.keys(existingHistory.timeByDay)[0]
    )
  ) {
    return {
      timeByDay: {
        ...updatedHistory.timeByDay,
        ...existingHistory.timeByDay,
      },
      watchHistory: [
        ...updatedHistory.watchHistory,
        ...existingHistory.watchHistory,
      ],
    };
  } else if (
    updatedHistory &&
    Object.keys(updatedHistory?.timeByDay)?.length > 0 &&
    Object.keys(existingHistory?.timeByDay)?.length
  ) {
    return {
      timeByDay: {
        ...existingHistory.timeByDay,
        ...updatedHistory.timeByDay,
      },
      watchHistory: [
        ...existingHistory.watchHistory,
        ...updatedHistory.watchHistory,
      ],
    };
  }
  return updatedHistory;
};
