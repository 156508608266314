import { h, FunctionalComponent, Fragment } from "preact";
import { useEffect, useState } from "preact/hooks";
import { styled } from "linaria/react";

import { appRoute } from "util/routerUtil";
import { useAnalytics } from "hooks/analyticsHook";
import mq from "style/breakpoints";
import useUser from "hooks/userHook";
import Colors from "style/colors";
import Text from "components/shared/Text";
import Icon, { IconTypes } from "components/shared/Icon";
import Button from "components/shared/Button";
import { useWindowSize } from "hooks/windowHook";
import UserUtil from "util/userUtil";
import { RenderIconMenuMobile } from "./RenderIconMenuMobile";

interface ProfileMenuMobileProps {
  setDisabledScroll?: (status: boolean) => void;
  setReturnPlansPayments?: () => void;
}

const ProfileMenuMobile: FunctionalComponent<ProfileMenuMobileProps> = ({
  setDisabledScroll,
  setReturnPlansPayments,
}: ProfileMenuMobileProps) => {
  const { height } = useWindowSize();
  const [isVisible, setIsVisible] = useState(false);
  const { user, logout } = useUser();
  const { track } = useAnalytics();
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const isOrg = user && UserUtil.isEnabledAdminOwners(user || undefined);

  const handleLogout = async (e) => {
    e.preventDefault();
    typeof window !== "undefined" &&
      localStorage.setItem("notificationPermission", "null");
    track("CTA Tapped", {
      name: "Sign Out",
      source: "Profile Menu",
    });

    track("Signed Out", {
      email: user?.email,
      source: "Profile Menu",
    });

    await logout();
    localStorage.removeItem("userSurvey");
    localStorage.removeItem("deepLink");
  };

  const getDownloadAppUrl = () => {
    return navigator.vendor.search("Apple") >= 0
      ? window.open("//apps.apple.com/us/app/romwod/id1175346453")
      : window.open(
          "//play.google.com/store/apps/details?id=com.romwodllc.android"
        );
  };
  const openIntercomChat = (e) => {
    e.preventDefault();
    if (typeof window !== "undefined" && window.Intercom) {
      window.Intercom("show");
    }
  };

  useEffect(() => {
    setIsVisible(openProfileMenu || false);
  }, [openProfileMenu]);

  if (!user) return null;

  const SupportMenu = () => {
    return (
      <Fragment>
        <h2>Support</h2>
        <Item onClick={openIntercomChat}>
          <div className={"contact-support"}>
            <span>{RenderIconMenuMobile("contact-support")}</span>
            <Text size="M">Contact Support</Text>
            <div className={"circle"} />
          </div>{" "}
          <Icon name={IconTypes.AngleRight} size="S" />
        </Item>
        <Item
          onClick={() => {
            window.open("https://support.pliability.com");
          }}
        >
          <div>
            <span>{RenderIconMenuMobile("help-center")}</span>
            <Text size="M">Help Center</Text>
          </div>{" "}
          <Icon name={IconTypes.AngleRight} size="S" />
        </Item>
        <Item
          onClick={() => {
            appRoute("/settings/terms-and-conditions");
            setOpenProfileMenu(false);
          }}
        >
          <div>
            <span>{RenderIconMenuMobile("terms-conditions")}</span>
            <Text size="M">Terms & Conditions</Text>
          </div>{" "}
          <Icon name={IconTypes.AngleRight} size="S" />
        </Item>
        <Item
          onClick={() => {
            appRoute("/settings/privacy-policy");
            setOpenProfileMenu(false);
          }}
          style={{ marginBottom: "16px" }}
        >
          <div>
            <span>{RenderIconMenuMobile("privacy-policy")}</span>
            <Text size="M">Privacy Policy</Text>
          </div>{" "}
          <Icon name={IconTypes.AngleRight} size="S" />
        </Item>
      </Fragment>
    );
  };

  const AccountMenu = () => {
    return (
      <Fragment>
        <h2>Your Account</h2>
        <Item
          onClick={() => {
            appRoute("/settings/manage-profile");
            setOpenProfileMenu(false);
          }}
        >
          <div>
            <span>{RenderIconMenuMobile("edit-profile")}</span>
            <Text size="M">Edit Profile</Text>
          </div>
          <Icon name={IconTypes.AngleRight} size="S" />
        </Item>
        {!UserUtil.isEnabledAdminOwners(user || undefined) && (
          <Fragment>
            <Item
              onClick={() => {
                appRoute("/settings/security");
                setOpenProfileMenu(false);
              }}
            >
              <div>
                <span>{RenderIconMenuMobile("security")}</span>
                <Text size="M">Security</Text>
              </div>{" "}
              <Icon name={IconTypes.AngleRight} size="S" />
            </Item>
            <Item
              onClick={() => {
                setReturnPlansPayments?.();
                appRoute("/settings/plans-and-payment");
                setOpenProfileMenu(false);
              }}
            >
              <div>
                <span>{RenderIconMenuMobile("plan-payment")}</span>
                <Text size="M">Plan & Payment</Text>
              </div>{" "}
              <Icon name={IconTypes.AngleRight} size="S" />
            </Item>
          </Fragment>
        )}
        <Item
          onClick={() => {
            typeof window !== "undefined" && getDownloadAppUrl();
          }}
        >
          <div>
            <span>{RenderIconMenuMobile("download-app")}</span>
            <Text size="M">Download App</Text>
          </div>
          <Icon name={IconTypes.AngleRight} size="S" />
        </Item>
      </Fragment>
    );
  };

  const OrgAdminMenu = () => {
    return (
      <Fragment>
        <h2>Admin</h2>
        <Item
          onClick={() => {
            appRoute("/team?tab=members");
            setOpenProfileMenu(false);
            setDisabledScroll?.(false);
          }}
        >
          <div>
            <span>{RenderIconMenuMobile("members")}</span>
            <Text size="M">Members</Text>
          </div>
          <Icon name={IconTypes.AngleRight} size="S" />
        </Item>
        <Item
          onClick={() => {
            appRoute("/team?tab=team-details");
            setOpenProfileMenu(false);
            setDisabledScroll?.(false);
          }}
        >
          <div>
            <span>{RenderIconMenuMobile("team-details")}</span>
            <Text size="M">Team Details</Text>
          </div>
          <Icon name={IconTypes.AngleRight} size="S" />
        </Item>
        <Item
          onClick={() => {
            appRoute("/settings/security");
            setOpenProfileMenu(false);
            setDisabledScroll?.(false);
          }}
        >
          <div>
            <span>{RenderIconMenuMobile("security")}</span>
            <Text size="M">Security</Text>
          </div>{" "}
          <Icon name={IconTypes.AngleRight} size="S" />
        </Item>
        <Item
          onClick={() => {
            appRoute("/team?tab=plans-and-payments");
            setOpenProfileMenu(false);
            setDisabledScroll?.(false);
          }}
        >
          <div>
            <span>{RenderIconMenuMobile("plans-and-payment")}</span>
            <Text size="M">Plans & Payment</Text>
          </div>
          <Icon name={IconTypes.AngleRight} size="S" />
        </Item>
      </Fragment>
    );
  };

  return (
    <Content>
      <div>
        <Container
          onClick={() => {
            setDisabledScroll?.(!openProfileMenu);
            setOpenProfileMenu(!openProfileMenu);
          }}
          data-testid="menu-mobile"
        >
          {isVisible ? (
            <Icon name={IconTypes.X} size="M" />
          ) : (
            RenderIconMenuMobile("menu")
          )}
        </Container>
      </div>
      {isVisible && (
        <Fragment>
          <Menu height={height - 52}>
            {isOrg ? (
              <Fragment>
                <OrgAdminMenu />
                <MenuDivider />
                <AccountMenu />
                <MenuDivider />
                <SupportMenu />
              </Fragment>
            ) : (
              <Fragment>
                <AccountMenu />
                <MenuDivider />
                <SupportMenu />
              </Fragment>
            )}
            <Footer height={height}>
              <Button size="S" text="Sign Out" handleClick={handleLogout} />
            </Footer>
          </Menu>
        </Fragment>
      )}
    </Content>
  );
};

export default ProfileMenuMobile;

const MenuDivider = styled.div`
  height: 1px;
  margin: 24px 0;
  background: ${Colors.bgGrey1};
`;

const Content = styled.div`
  width: 24px;
  height: 24px;
  background: #000000 !important;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Container = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  > span {
    color: ${Colors.txtLight};
    font-size: 16px;
  }
`;

const Menu = styled.div<{ height: number }>`
  position: absolute;
  z-index: 4;
  top: 52px !important;
  left: 0;
  right: 0;
  flex-flow: column;
  justify-content: space-between;
  background: ${Colors.bgDark0};
  border-top: 1px solid rgba(255, 255, 255, 0.16);
  overflow: scroll;
  height: ${({ height }) => (height ? `${height}px` : "0")} !important;

  ::-webkit-scrollbar {
    display: none !important;
  }

  > div:last-child {
    border-radius: 0 0 12px 12px;

    > span:first-child {
      transform: rotate(180deg);
    }
  }

  h2 {
    color: ${Colors.txtLight};
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    text-transform: capitalize;
    padding: 16px 16px 8px 16px;
  }
`;

const Item = styled.div`
  cursor: pointer;
  width: 100%;
  padding: 0 16px;
  transition: opacity 0.25s ease-in-out, background-color 0.25s ease-in-out;
  color: ${Colors.txtLight};
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;
    padding: 16px 0;
    border-radius: 8px;

    > span {
      margin-right: 12px;
      color: ${Colors.txtLight} !important;
      font-size: 16px !important;
      line-height: 24px !important;
      display: flex;
      :before {
        color: ${Colors.txtLight};
        font-size: 24px;
      }
    }
  }
  > span {
    color: ${Colors.txtGrey3};
  }

  .contact-support {
    position: relative;
    .circle {
      position: absolute;
      height: 15px;
      width: 15px;
      background: #000000;
      border-radius: 100px;
      left: 4.75px;
    }
  }
`;

const Footer = styled.div<{ height: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  margin-bottom: 32px;

  > button {
    background: ${Colors.bgDark4};
    padding: 10px 16px !important;
    margin-bottom: ${({ height }) =>
      height <= 655 ? "180px" : "0"} !important;

    > div {
      color: ${Colors.txtLight};
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
    :hover:active {
      background-color: transparent;
    }

    :hover {
      opacity: 1;
    }
  }
`;
