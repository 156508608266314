export async function generateHMAC() {
  const key = `${process.env.HMAC_KEY || ""}`;
  const date = new Date();
  const epoch = Math.round(date.valueOf() / 1000);

  const cryptoKey = await crypto.subtle.importKey(
    "raw",
    new TextEncoder().encode(atob(key).trim()),
    { name: "HMAC", hash: "SHA-256" },
    false,
    ["sign", "verify"]
  );

  const sig = await crypto.subtle.sign(
    "HMAC",
    cryptoKey,
    new TextEncoder().encode(epoch.toString())
  );

  const hmac = hex(sig);
  return { Timestamp: epoch.toString(), Hash: hmac };
}

function hex(buff: ArrayBuffer): string {
  return (
    [].map
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      .call(new Uint8Array(buff), (b) => ("00" + b.toString(16)).slice(-2))
      .join("")
  );
}
