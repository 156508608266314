import { useAction, useSelector } from "@preact-hooks/unistore";
import { WelcomeModalType } from "models/welcomeModal";
import { actions } from "store";

interface ModalHook {
  welcomeModal: WelcomeModalType;
  setWelcomeModal: (welcomeModal: WelcomeModalType | null) => void;
}

export const useWelcomeModal = (): ModalHook => {
  const { welcomeModal } = useSelector("welcomeModal");
  const setWelcomeModal = useAction(actions.setWelcomeModal);
  return { welcomeModal, setWelcomeModal };
};
