import useUser from "hooks/userHook";
import { FunctionalComponent } from "preact";
import { useEffect } from "preact/hooks";

// import { bootMessenger } from "util/intercomChatUtil";
import { keysToSnake } from "helpers/humps";
import { useWindowSize } from "hooks/windowHook";
import { usePlayingVideo } from "hooks/videoHook";

const appId = process.env.INTERCOM_APP_ID;
const intercomBooted = false;

const IntercomMessenger: FunctionalComponent = () => {
  const { getIntercomUser } = useUser();
  const { playingVideo } = usePlayingVideo();

  const pathname = typeof window !== "undefined" && window.location.pathname;
  const showIntercom =
    /signin/.test(pathname || "") ||
    /signup/.test(pathname || "") ||
    /org-signup/.test(pathname || "");
  const noShowDiscover = /discover/.test(pathname || "");
  const intercomUser = getIntercomUser();
  const { width } = useWindowSize();

  const settings = {
    ...keysToSnake({
      ...intercomUser,
      appId,
      alignment: `right`,
      verticalPadding: "24",
      horizontalPadding: "32",
      hideDefaultLauncher:
        (width <= 500 && !showIntercom) || noShowDiscover || playingVideo
          ? true
          : false,
    }),
  };

  useEffect(() => {
    // if (width > 425)
    if (!intercomBooted && window.Intercom) {
      window.Intercom("boot", settings);
      // bootMessenger(settings);
    }
  }, [settings]);

  return null;
};

export default IntercomMessenger;
