import { pubPathNoTrailingSlash } from "config";
import { route as defRoute } from "preact-router";

export function appRoute(url: string, replace?: boolean) {
  return defRoute(prefixPubPath(url), replace);
}

export function prefixPubPath(path?: string): string {
  let patchedUrl = path;
  if (
    pubPathNoTrailingSlash &&
    patchedUrl &&
    !patchedUrl.startsWith(pubPathNoTrailingSlash)
  ) {
    const sep = !patchedUrl.startsWith("/") ? "/" : "";
    patchedUrl = `${pubPathNoTrailingSlash}${sep}${patchedUrl}`;
  }
  return patchedUrl ?? "";
}
