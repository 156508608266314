import { FunctionalComponent, h } from "preact";
import { styled } from "linaria/react";
import mq from "style/breakpoints";
import { pubPathNoTrailingSlash } from "config";

const Image = styled.img<{ size: "L" | "M" | "S" }>`
  height: ${({ size }) => {
    switch (size) {
      case "L":
        return "4rem";
      case "M":
        return "2rem";
      case "S":
        return "1rem";
    }
  }};
`;

const Container = styled.div<{ isLoading: boolean }>`
  position: fixed;
  width: 100%;
  bottom: 65px;
  left: 0;
  display: flex;
  visibility: ${({ isLoading }) => (isLoading ? "visible" : "hidden")};
  padding: 1rem 0;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  ${mq.minSm} {
    bottom: 0;
  }
`;

interface LoaderProps {
  isLoading: boolean;
  size: "L" | "M" | "S";
  unwrapped?: boolean;
}

const Loader: FunctionalComponent<LoaderProps> = ({
  isLoading,
  size,
  unwrapped,
}: LoaderProps) =>
  unwrapped ? (
    <Image
      src={`${pubPathNoTrailingSlash}/assets/images/loader-black.gif`}
      size={size}
    />
  ) : (
    <Container isLoading={isLoading} className="button-loading">
      <Image
        src={`${pubPathNoTrailingSlash}/assets/images/loader-white.gif`}
        size={size}
      />
    </Container>
  );

export default Loader;
