import { FunctionalComponent } from "preact";
import { useEffect } from "preact/hooks";
import useUser from "hooks/userHook";
import { toCamel } from "helpers/humps";
import User from "util/userUtil";

const NonceLoader: FunctionalComponent = () => {
  const { refreshUser } = useUser();
  const criticalSearchParams =
    typeof window !== "undefined" ? new URL(window.location.href) : null;
  const criticalSearchOpts: {
    jwtNonce: string;
  } = {
    jwtNonce: "",
  };

  if (criticalSearchParams) {
    for (const [key, value] of criticalSearchParams.searchParams) {
      criticalSearchOpts[toCamel(key)] = value;
    }
  }

  const { jwtNonce } = criticalSearchOpts;

  useEffect(() => {
    if (jwtNonce && typeof window !== "undefined") {
      User.setToken(jwtNonce);
      (async () => {
        await refreshUser();
        window.location.href = window.location.pathname;
      })();
    }
  }, [jwtNonce]);
  return null;
};

export default NonceLoader;
